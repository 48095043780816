import { routeConstants } from "../constants";

/**
 * Gets route.
 * @param {String} routeName
 * @param {String} language
 * @param {Object} qs
 */
const getRoute = (routeName, language = "pl", qs={}) => {
  const hasQueryString = typeof qs === 'object' && Object.keys(qs).length > 0;
  const queryString = Object.keys(qs).map(key => `${key}=${qs[key]}`).join('&');

  if (!routeName || routeName === "/") {
    return language === "pl" ? "/" : `/${language}`;
  }

  const specificRoute = language === "pl"
    ? routeConstants[routeName]
    : routeConstants["_EN"][routeName];

  return `${specificRoute}${(hasQueryString ? `?${queryString}` : '')}`;
};

const routeService = {
  getRoute
};

export default routeService;
