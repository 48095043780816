import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { routeService } from "../../services";
import Ink from "react-ink";
import "./NotFoundPage.scss";

const NotFoundPage = props => {
  const currentLanguage =
    props.location.pathname.indexOf("/en") === 0 ? "en" : "pl";
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('notFoundPage.metadata.title')}</title>
        <meta name="description" content={t('notFoundPage.metadata.description')} />
        <meta name="keywords" content={t('notFoundPage.metadata.keywords')} />
      </Helmet>

      <div className="insideContainer notFoundPageContainer">
        <div className="backgroundColorSpace"></div>

        <div className="narrowerPageContainer__inside notFoundPageContainer__inside notFoundPageContainer__inside--minusMargin">
          <div className="whiteBox">
            <div className="alertPictogram"></div>
            <h3>{t("notFoundPage.header")}</h3>
            <p>
              {t("notFoundPage.subheader")}{" "}
              <strong>{props.location.pathname}</strong>{" "}
              {t("notFoundPage.subheaderExtend")}
              <br />
              {t("notFoundPage.subheaderExtend2")}{" "}
              <Link to={routeService.getRoute("", currentLanguage)}>
                {t("notFoundPage.subheaderExtend3")}
              </Link>
              .
            </p>
            <div className="button-container">
              <Link
                className="MSButton MSButton--orange MSButton--pointer"
                to={routeService.getRoute("", currentLanguage)}
              >
                <span>{t("notFoundPage.button.goToHomePage")}</span>
                <Ink />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFoundPage);
