import axios from "axios";
import baseConstants from "./../constants/baseConstants";
export const api = axios.create({
  baseURL: `${baseConstants.STRAPI_URL}/blogs`,
  withCredentials: true,
});

export const getNumberOfBlogs = () => {
  return getMethod("/count");
};

export const getBlogById = (id) => {
  return getMethod(`/${id}`);
};

export const getAllBlogs = () => {
  return getMethod(`?_sort=created_at:DESC`);
};

export const getMainBlogs = () => {
  return getMethod(`?_sort=created_at:DESC&mainPage=true`);
};

export async function getMethod(url) {
  try {
    return await api.get(url).then((resp) => {
      return resp.data;
    });
  } catch (error) {
    return [];
  }
}

export function postMethod(url) {
  return api.post(url).then((resp) => {
    return resp.data;
  });
  //todo add error handle
}
