const backendTranslationsEN = {
  ByWindow: "At the window",
  Failed: "Failed",
  ForHowManyPeopleTable: "Covers",
  NewReservationCouldNotBeCreated: "We could not create the new reservation",
  NewReservationHasBeenCreated: "The new reservation has been created",
  NonSmoking: "Non smoking",
  Outdoor: "Outside",
  ReservationHasBeenUpdated: "The reservation has been updated",
  "acceptance.required": "Accept required agreements",
  "account.changePassword": "Change password",
  "account.confirmRemoveAccount":
    "Are you sure you want to delete the account? All your reservations will be automatically rejected.",
  "account.removeAccount": "Delete account",
  "account.removeRequest": "To delete your account, enter your password",
  "agreement.canRevert":
    "mojstolik.pl informuje o możliwości wycofania zgody na przetwarzanie danych osobowych.",
  "agreement.commercialInformationContact":
    "Wyrażam zgodę na przesyłanie przez Table4Rest sp. z o.o. informacji handlowej za pomocą środków komunikacji elektronicznej, w szczególności na podany przeze mnie numer telefonu oraz adres poczty elektronicznej, w tym SMS i MMS.",
  "agreement.marketingContact":
    "Wyrażam zgodę na kontaktowanie się ze mną przez Table4Rest sp. z o. o.,  w tym SMS i MMS, jaki i kontakt z użyciem telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących dla celów marketingu bezpośredniego, w tym na podany przeze mnie numer telefonu oraz adres poczty elektronicznej.",
  "agreement.marketingDataUsage":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celach marketingowych usług i produktów własnych oraz innych podmiotów, które są oferowane za pośrednictwem aplikacji MójStolik.",
  "agreement.personalData":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl",
  "agreement.readWithdrawal":
    "Zapoznałem się z pouczeniem o prawie odstąpienia od",
  "agreement.marketingAgreement":
    "Zgoda marketingowa",
  "promotion.readWithdrawal":
    "Zapoznałem się z regulaminem promocji, w szczególności z pouczeniem o prawie odstąpienia od umowy",
  "agreement.thirdPartDataUsage":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji.",
  "agreement.warningUncheck":
    "Odwołonie zgody uniemożliwia korzystanie z aplikacji. Aby ją odwołać, należy usunąć konto.",
  "api.connectionLost":
    "Internet connection has been lost. The application will be loaded again",
  "app.readAgreement": "Terms and conditions of the MojStolik.pl service",
  "changePass.lowerLetter": "- contains lowercase letters",
  "changePass.newPass": "New password",
  "changePass.numeric": "- contain numbers",
  "changePass.oldPass": "Current password",
  "changePass.passHaveToBe": "Password should",
  "changePass.passLength": "- have at least 6 characters",
  "changePass.specialChar": "- contain a special character like '#@!'",
  "changePass.upperLetter": "- contain at least 1 capital letter",
  "common.accept": "Confirm",
  "common.add": "Add",
  "common.alertCloseWindow": "you will close selection window",
  "common.back": "Back",
  "common.bankAccountNumber": "Bank Account Number",
  "common.buildingNumber": "Building number",
  "common.cancel": "Cancel",
  "common.changeButton": "Change",
  "common.city": "City",
  "common.clear": "Clear",
  "common.close": "Close",
  "common.confirm": "Confirm",
  "common.confirmDecline": "Confirm/Reject",
  "common.couldNotDownloadData":
    "The data was not downloaded. Please refresh the application",
  "common.dayShort.friday": "Fri",
  "common.dayShort.monday": "Mon",
  "common.dayShort.saturday": "Sat",
  "common.dayShort.sunday": "Sun",
  "common.dayShort.thursday": "Thu",
  "common.dayShort.tuesday": "Tue",
  "common.dayShort.wednesday": "Wed",
  "common.delete": "Remove",
  "common.detailsButton": "Details",
  "common.error": "Error",
  "common.gr": "gr",
  "common.help": "Help",
  "common.hourShort": "hour",
  "common.localNumber": "Restaurant number",
  "common.name": "Name",
  "common.new": "New",
  "common.next": "Continue",
  "common.nextButton": "Next",
  "common.noData": "There are no data to display",
  "common.noValue": "No",
  "common.ok": "OK",
  "common.password": "Password",
  "common.preferences": "Preference",
  "common.requiredInputError": "Field required",
  "common.save": "Save",
  "common.search": "Search",
  "common.send": "Send",
  "common.shortPersons": "{{persons}} p.",
  "common.street": "Street",
  "common.successful": "Success",
  "common.undoButton": "Back",
  "common.unit": "Unit",
  "common.vatId": "Tax Number",
  "common.yesValue": "Yes",
  "common.zipCode": "Post code",
  "common.zl": "PLN",
  "company.addNewAction": "New Company has been added",
  "company.addNewCompany": "Do you want to add new Company?",
  "company.addressData": "Address details",
  "company.buildingNumber": "Building number",
  "company.city": "City",
  "company.companyExistsError": "Company already exists",
  "company.country": "Country",
  "company.localNumber": "Restaurant number",
  "company.name": "Company name",
  "company.nipNumber": "VAT number",
  "company.postalCode": "Post code",
  "company.questionUpdateModal": "Update company details?",
  "company.street": "Street",
  "company.updateAction": "Company details have been updated",
  "company.updateCompany": "Update already existing company?",
  "company.vatId": "Tax Number",
  "config.communication.confirmationReservation": "Reservation confirm",
  "config.communication.modificationReservation": "Change of reservation",
  "config.communication.notifTitle": "PUSH notifications",
  "config.communication.rejectionReservation": "Canceling a reservation",
  "config.communication.remindNotification": "Reminders about booking",
  "config.navbar.account": "Account",
  "config.navbar.communication": "Communication",
  "config.navbar.configuration": "Configuration",
  "config.navbar.myaccount": "Account",
  "config.navbar.mypreferences": "Preference",
  "configuration.customerProfiles": "Guests profiles",
  "configuration.employeeGroups": "Employees Groups",
  "configuration.help": "Help",
  "configuration.restaurant": "Restaurant",
  "cuisine.arabic": "Arabic cuisine",
  "cuisine.romania": "Romania cuisine",
  "customer.accountDetails": "Account details",
  "customer.addEdit": "Add / Modify",
  "customer.blocked": "Your account has been blocked. You cannot book.",
  "customer.confirmText": "Reservation",
  "customer.createInvoice": "Create invoice",
  "customer.createNewProfile": "New profile has been created",
  "customer.createNewProfileModal": "Create new profile?",
  "customer.deleteProfile": "Delete profile",
  "customer.description": "Description",
  "customer.editCompany": "Edit company details",
  "customer.editProfile": "Profile has been updated",
  "customer.editReservingCustomerData":
    "Edit the details of person that did reservation",
  "customer.emailAddress": "e-mail",
  "customer.errorProfileAction": "The profile is already assigned to the Guest",
  "customer.existError": "Guest already exists",
  "customer.firstCreateCompany": "*first please chose the company",
  "customer.groupChooseLabel": "Select",
  "customer.labels.blocked": "Blocked",
  "customer.labels.customerName": "Name",
  "customer.labels.emailLabel": "E-mail",
  "customer.labels.firstNameLabel": "Name",
  "customer.labels.groupLabel": "Group",
  "customer.labels.lastNameLabel": "Surname",
  "customer.labels.phoneNumber": "Phone",
  "customer.labels.statusLabel": "status",
  "customer.listOfProfiles": "List of profiles",
  "customer.mail": "e-mail",
  "customer.name": "Name",
  "customer.newPasswordWasSent":
    "The new password has been send by SMS message",
  "customer.noCustomer": "Guest does not exist",
  "customer.noCustomers": "No Guests",
  "customer.noReservations": "No reservation",
  "customer.onePersonFound": "1 person has been found",
  "customer.password": "Password",
  "customer.password.mismatch": "Wrong current password",
  "customer.passwordHasChange": "Password has been changed",
  "customer.permissionForHoldingPersonalData":
    "Confirmation for the approval to process personal data",
  "customer.profile": "Profile",
  "customer.profileDetails": "Profile details",
  "customer.profileExistsError": "Profile already exists",
  "customer.registration.code": "Your activation code: _CODE_",
  "customer.registration.invalid": "Invalid activation code",
  "customer.registration.sendByEmail":
    "Problem with SMS - code has been send to provided email.",
  "customer.remarks": "Remarks",
  "customer.remarksToClient": "Remarks about Guest",
  "customer.remarksToReservation": "Remarks to the reservation",
  "customer.removeProfileAction": "Profile has been deleted",
  "customer.removeProfileAction.error": "Profile has not been deleted",
  "customer.reservationHistory": "Reservation History",
  "customer.reservingCustomerData": "Details of person doing the reservation",
  "customer.saveChanges": "Save changes?",
  "customer.surname": "Surname",
  "customer.telephone": "Phone",
  "customer.telephoneNumber": "Phone number",
  "customer.updateClient": "Update Guest details?",
  "customer.updateProfileModal": "Update the profile?",
  "editReservation.send": "Send",
  "editReservation.sendMessage":
    "A request to change the reservation parameters has been sent.",
  "email.already.exist": "The email address already exists",
  error: "Error occurred. Please try again later.",
  "error.cannotCreateReservation": "Your request could not be sent",
  "error.connectionWithServerLost": "Connection lost",
  "error.connectionWithServerLostTryLater": "Connection lost. Try again later",
  "error.email.already.exist": "The email address already exists",
  "error.facebook.account.already.exist":
    "A Facebook account has already been created for this number. Use Facebook login",
  "error.google.account.already.exist":
    "A Google account has already been created for this number. Use Google login",
  "error.notFoundAddress": "Location not found",
  "error.phone.already.exist":
    "The number already exists. Log in by phone number or use the restore password option.",
  "filters.doFilter": "Filter",
  "filters.fromToday": "From today",
  "filters.toToday": "Until today",
  "filters.today": "Today",
  "footer.Next": "Next",
  "footer.Previous": "Previous",
  "footer.alertOnBack": "Drop changes?",
  "group.removeErrorAction": "The group is already assigned to the user",
  "image.notFound": "Image not found",
  "invoice.vatId": "VAT ID: {{vatId}}",
  login: "Login",
  "login.accountDoesNotExist": "The account does not exist",
  "login.accountName": "User name",
  "login.doLogin": "Log in",
  "login.forgotPassword": "Forgot password?",
  "login.forgottenPasswordLink": "Do you forgot the password?",
  "login.generalTitle": "Log in",
  "login.insertLoginData": "Please insert login details",
  "login.insertManagerPassword": "Please put manager email address",
  "login.loggingToSystem": "Login to the system",
  "login.loginOrPasswordWrong": "Login or password is incorrect",
  "login.loginText": "Login",
  "login.logout": "Logout",
  "login.logout.message": "You have been logout",
  "login.newAccount": "New account",
  "login.newUpdateReady":
    "There is new version of application available. Please update",
  "login.passwordRecovery": "Password recovery",
  "login.passwordSent.sms": "The new password is: _PASSWORD_",
  "login.passwordSent.sms.too.often":
    "Too often try to change the password, try again in a moment",
  "login.passwordSentToEmail":
    "The new password has been sent for the given email address",
  "login.phoneNumberOrMail": "Phone number or email address",
  "login.recover": "Recover",
  "login.resetPasswordLabel": "E-mail address",
  "login.restaurantChanged": "The restaurant has been changed",
  "login.thisAccountAlreadyExist": "The user already exist",
  "login.updateRequired":
    "You need to update your application. It might work not correctly",
  "login.userUnauthorized": "Login or password is incorrect",
  "login.userUnauthorized.useFacebook":
    "Bad login or password. Try to login by Facebook or restote password",
  "login.userUnauthorized.useGoogle":
    "Bad login or password. Try to login by Google or restore password.",
  "login.yourPassword": "Your password",
  "map.searchArea": "Search the area",
  "menu.areaControl": "Rooms management",
  "menu.config": "Configuration",
  "menu.configPage": "Settings",
  "menu.configuration": "Configuration",
  "menu.currentReservations": "Current reservation",
  "menu.customers": "Guests",
  "menu.homePage": "Main",
  "menu.invoice": "Invoices",
  "menu.login": "Login",
  "menu.logout": "Log out",
  "menu.myReserv": "Reservation",
  "menu.myReservations": "My reservations",
  "menu.newAccount": "My account",
  "menu.passwordRecovery": "Password recover",
  "menu.reservationpage": "New reservation",
  "menu.reservations": "All reservations",
  "menu.resetPassword": "Password reminder",
  "menu.restaurant": "Restaurant",
  "menu.sale": "Promotions",
  "menu.search": "Search",
  "myReservation.doRate": "Rate",
  "myReservation.doRateQuestion":
    "Would you like to rate reservation {{rate}}/5?",
  "myReservation.doRateSuccess": "The rating has been issued",
  "myReservation.doRateTitle": "How do you rate your booking?",
  "myReservation.doSuccessRemove":
    "The reservation has been removed from the list",
  "myReservation.yourRate": "Your rate",
  "navbar.allReserv": "All",
  "navbar.areaEdition": "Room edit",
  "navbar.cancelChangesReservation": "Cancel the reservation change?",
  "navbar.cancelReservation": "Cancel reservation?",
  "navbar.changePass": "Change password",
  "navbar.clientPreview": "Guest view",
  "navbar.companyEdition": "Edit company details",
  "navbar.configuration": "Configuration ",
  "navbar.currentReserv": "Current",
  "navbar.currentReservations": "Current reservations",
  "navbar.customers": "Guests",
  "navbar.dataCustomer": "Details of person doing the reservation",
  "navbar.defaultCancel": "Cancel?",
  "navbar.employee": "Employees",
  "navbar.historyReserv": "Historical",
  "navbar.invoiceEdition": "Invoice edit",
  "navbar.invoicePreview": "Invoice view",
  "navbar.label.editReservation": "Change of reservation",
  "navbar.label.myProfile": "My profile",
  "navbar.label.myReservation": "My reservations",
  "navbar.label.reservation": "Reservation",
  "navbar.label.reservationDetails": "Reservation details",
  "navbar.label.sale": "Promotions",
  "navbar.newReservation": "New Reservation",
  "navbar.reservationActualState": "Current reservation status",
  "navbar.reservationEdit": "Edit reservation",
  "navbar.reservationPreview": "Reservation view",
  "navbar.reservations": "Reservations",
  "notification.body.Canceled":
    "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been canceled.",
  "notification.body.Completed":
    "Thank you for reservation in _RESTAURANT_ at _DAY_ _TIME_.",
  "notification.body.Confirmed":
    "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been confirmed.",
  "notification.body.Rejected":
    "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been cancelled. _MESSAGE_",
  "notification.body.WaitingForApproval":
    "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ is waiting for approval.",
  "notification.body.WaitingForConfirmation":
    "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ is waiting for confirmation.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Message from _RESTAURANT_",
  "notification.parameters.updated.body":
    "Your reservation in _RESTAURANT_ at _DAY_:_TIME_ has been updated.",
  "notification.parameters.updated.title": "Reservation update",
  "password.errors.weak": "Password is too weak",
  "payment.card": "credit card",
  "payment.cash": "cash",
  "payment.otherType": "other",
  "payment.transfer": "money transfer",
  "payment.type": "Payment method",
  "preference.category.cuisine": "Cuisine",
  "preference.category.cuisine.polish": "Polish Cuisine",
  "preference.category.price": "Price",
  "promotions.emptyList": "No promotions",
  "registration.activeCode": "Activation code",
  "registration.addressEmail": "Email address",
  "registration.codeInformation":
    "The activation code has been sent to the given phone number {{phone}}, enter it below and confirm with the button",
  "registration.createAccount": "New account",
  "registration.createSuccess": "Account has been created",
  "registration.differentPasswords": "The passwords do not match",
  "registration.finishButton": "Finish registration",
  "registration.formIsInvalid":
    "The data has not been filled correctly. Use the help at the bottom.",
  "registration.generalTitle": "Registration",
  "registration.phoneNumber": "Phone number",
  "registration.phoneToShort": "Number too short",
  "registration.readAgreement": "Read the regulations",
  "registration.repeatPassword": "Confirm password",
  "registration.requiredFieldsFirstStep":
    "The phone number, e-mail and password are mandatory.",
  "registration.weakPassword": "Password is too weak",
  "request.blocked": "Request has been blocked, please try again later.",
  "reservation.addNewAction": "The new reservation has been created",
  "reservation.addNote": "Add note",
  "reservation.addRemarkQuestion": "Add note to the reservation",
  "reservation.alreadyRated": "Reservation has been already rated",
  "reservation.basicInformation": "Basic information",
  "reservation.cancelReservation": "Cancel",
  "reservation.cancelReservationQuestion":
    "Are you sure you want to cancel the booking?",
  "reservation.changeReservation": "Change",
  "reservation.changeReservationParameters": "Change reservation details",
  "reservation.changeStatusAction": "Status changed on",
  "reservation.chooseStatus": "Select status",
  "reservation.comunications": "Messages",
  "reservation.createReservation": "Create reservation on ",
  "reservation.defaultTime": "Preferred booking time {{reservationTime}}.",
  "reservation.doReservation": "Book",
  "reservation.duration": "Duration",
  "reservation.durationTime": "Duration of booking  {{durationTime}}",
  "reservation.editData": "Edit data",
  "reservation.editReservation": "Edit reservation",
  "reservation.errorNewAction": "The new reservation could not be created",
  "reservation.errorPast": "The new reservation could not be created in past",
  "reservation.filter.fromDate": "From ",
  "reservation.filter.lastName": "Surname",
  "reservation.filter.toDate": "Till",
  "reservation.forHowLong": "Duration",
  "reservation.generalTitle": "Booking",
  "reservation.group": "Group",
  "reservation.individual": "Individual",
  "reservation.informationOnReservation": "Guest information",
  "reservation.labels.client": "Client",
  "reservation.labels.date": "Date",
  "reservation.labels.dateTo": "For",
  "reservation.labels.firstName": "Name ",
  "reservation.labels.lastName": "Surname",
  "reservation.labels.number": "Reservation number",
  "reservation.labels.numberOfPeople": "Number of Guests",
  "reservation.labels.phone": "Phone",
  "reservation.labels.profil": "Profile",
  "reservation.labels.remarks": "Remarks",
  "reservation.labels.status": "Status",
  "reservation.labels.tableNumber": "Room - table",
  "reservation.lessSpotThanPeople":
    "The selected number of seats is smaller - continue?",
  "reservation.message": "MojStolik.pl message",
  "reservation.messages": "Write the message",
  "reservation.morePeople": "Greater number of people",
  "reservation.newPersonToReservation": "Add new person to the reservation?",
  "reservation.noFreeTables": "There are no available tables",
  "reservation.notExist": "Reservation does not exist",
  "reservation.numberOfGuests": "Number of Guests",
  "reservation.personsError": "Can not send a booking request for 0 persons",
  "reservation.pickDurationTime": "Choose the duration",
  "reservation.preferences": "Preferences",
  "reservation.remarks": "Remarks",
  "reservation.remarksFromRestaurant": "Restaurant comments",
  "reservation.remind.message":
    "The website MojStolik.pl reminds about booking at the _RESTAURANT_ on _DATE_ for _PERSONS_. To cancel a reservation, please click the link: _LINK_ . Booking service is provided by MojStolik.pl",
  "reservation.request.not.yet.available": "Reservation is not available yet.",
  "reservation.request.too.often": "The request just sent",
  "reservation.reservationDate": "Day",
  "reservation.spotsChosen": "Selected seats",
  "reservation.status": "Status",
  "reservation.status.Canceled": "Rejected",
  "reservation.status.Completed": "Booking completed",
  "reservation.status.Confirmed": "The reservation has been confirmed",
  "reservation.status.Delayed": "Delayed",
  "reservation.status.NotCompleted": "Unrealized booking",
  "reservation.status.Rejected": "The reservation was canceled",
  "reservation.status.WaitingForApproval":
    "The reservation is awaiting for approval",
  "reservation.status.WaitingForConfirmation": "Awaiting",
  "reservation.successRequest": "A reservation request has been sent",
  "reservation.updateAction": "The reservation has been updated",
  "reservation.updateReservation": "Updated reservation?",
  "reservation.updateReservationButton": "Cancel reservation",
  "reservation.whichHour": "Time",
  "reservation.wholeRoom": "The whole room",
  "reservationMenu.allActive": "All active",
  "reservationMenu.fromApp": "Requests from the application",
  "reservationMenu.toConfirmation": "To be confirmed",
  "reservationMenu.today": "For today",
  "reservationParams.restaurantName": "Restaurant name",
  "reservationParams.yourLocalization": "Your localization",
  "reservations.noReservations": "No reservation",
  "resetPassoword.generalTitle": "Password reminder",
  "resetPassoword.info":
    "Enter the phone number to which the account was registered. The password will be sent via SMS to this number",
  "resetPassoword.sendNewPassword": "Send password",
  "restaurant.aboutUs": "About us",
  "restaurant.bankAccountNumber": "Bank Account Number",
  "restaurant.buildingNumber": "Restaurant number",
  "restaurant.city": "City",
  "restaurant.closeHour": "Closed time",
  "restaurant.companyName": "Company name",
  "restaurant.dayFormat": "Dd – day of month",
  "restaurant.defaultReservationLength":
    "Predefined duration of the reservation",
  "restaurant.emailAddress": "E-mail",
  "restaurant.emptyList":
    "There are no restaurants that match your search criteria.",
  "restaurant.greaterThenZeroError": "Must be greater than 0",
  "restaurant.insertRestaurant": "Please insert Restaurant name",
  "restaurant.legend": "Legend",
  "restaurant.localNumber": "Restaurant number",
  "restaurant.logo": "Logo",
  "restaurant.maximalReservationLength": "Max duration of the reservation",
  "restaurant.menu.price": "{{price}} zł",
  "restaurant.minimalPeopleForGroup":
    "Min number of guests for group reservation",
  "restaurant.monthFormat": "MM - month",
  "restaurant.navbar.aboutRestaurant": "Restaurant",
  "restaurant.navbar.menu": "Menu",
  "restaurant.navbar.promotion": "Promotions",
  "restaurant.navigate": "Navigate to the restaurant",
  "restaurant.navigationApp": "Navigation application",
  "restaurant.nipNumber": "Tax Number",
  "restaurant.openHour": "Opening hour",
  "restaurant.pickDay": "Choose the date",
  "restaurant.pickHour": "Choose time",
  "restaurant.pickPersons": "Choose the number of persons",
  "restaurant.postalCode": "Post code",
  "restaurant.price.expensive": "Very expensive",
  "restaurant.price.extralow": "Extra cheap",
  "restaurant.price.low": "Cheap",
  "restaurant.price.medium": "Medium",
  "restaurant.productsServices": "Products / services",
  "restaurant.promotion.label": "Promotions",
  "restaurant.questionUpdateRestaurant":
    "You want to update the details about restaurant?",
  "restaurant.rate": "Rate",
  "restaurant.restaurant": "Restaurant",
  "restaurant.saveNavigationPick": "Remember the choice",
  "restaurant.seriesNumberTemplate": "Invoice numbering template",
  "restaurant.serverAddress": "server address",
  "restaurant.street": "Street",
  "restaurant.web.confirm": "Do you want to open a website?",
  "restaurant.writeCity": "City",
  "restaurant.yearEndFormat": "uu – short year, e.g. 18 ",
  "restaurant.yearFullFormat": "uuuu – long year, e.g. 2018",
  "restaurantPreview.doReservation": "Reserve",
  "room.firstRoomVisualization": "First please create the room visualization",
  "sms.send.problem":
    "Problem with sending SMS. Please try again later or use mobile application",
  "social.fbloginBtn": "Login with Facebook",
  "social.googleloginBtn": "Login with Google",
  "status.Canceled": "Rejected",
  "status.Confirmed": "Confirmed",
  "status.Rejected": "Rejected",
  "status.WaitingForConfirmation": "Waiting for confirmation",
  "token.expired": "Token expired",
  "token.wrong": "Wrong token provided",
  "user.acceptAgreement": "I accept the terms of service www.mojstolik.pl",
  "user.addNewAction": "Guest has been added",
  "user.cityAddress": "City",
  "user.dateOfBirth": "Date of birth",
  "user.editAction": "Guest has been updated",
  "user.email": "E-mail",
  "user.firstName": "First name",
  "user.lastName": "Last name",
  "user.name": "Name",
  "user.phone": "Phone",
  "user.postalCode": "Postal code",
  "user.sessionExpire": "Your session expired",
  "user.streetAddress": "Address",
  "user.successUpdate": "Personal data has been updated",
  "user.surname": "Surname",
  "user.updateQuestion": "Do you want to update your personal data?",
  "validation.fieldRequired": "This field is required",
  "validation.passwordRequired": "Password is required",
  "validation.phoneRequired": "Phone number is required",
  "reservationHistoryPage.sendMessage": "Send message",
  "reservationHistoryPage.send": "Send",
  "reservationHistoryPage.cancel": "Cancel",
  "reservationChatPage.send": "Send",
  'reservationChatPage.writeMessage': 'Message',
  "reservationHistoryPage.paragraph.invoice": "Invoice",
};

export default backendTranslationsEN;
