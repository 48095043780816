import React, { useState, useEffect } from "react";
import { routeService } from '../../services';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import classnames from 'classnames';
import "./CookiesPolicyLine.scss";

const CookiesPolicyLine = props => {
  const currentLanguage = props.location.pathname.indexOf('/en') === 0 ? 'en' : 'pl';
  const [hideCookiePolicy, setHideCookiePolicy] = useState(false);

  const closeCookieLink = () => {
    localStorage.setItem('hideCookiePolicy', 'true');
    setHideCookiePolicy(true);
  };

  useEffect(() => {
    const hideCookiePolicyLS = localStorage.getItem('hideCookiePolicy');
    if (hideCookiePolicyLS === 'true') {
      setHideCookiePolicy(true);
    } else {
      setHideCookiePolicy(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classnames({
          "cookiesPolicy": true,
          "cookiesPolicy--show": !hideCookiePolicy,
        })}>
        <div className="cookiesPolicy__inside">
          <span className="closeCookiesPolicy" onClick={() => {
            closeCookieLink()
          }}></span>
          <Translation>{t => (
            <p>
              {t('component.cookiesPolicyLine.text')}
              <Link to={routeService.getRoute('PRIVACY_POLICY', currentLanguage)}>{t('component.cookiesPolicyLine.privacyPolicyText')}</Link>
            </p>
          )}</Translation>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CookiesPolicyLine);
