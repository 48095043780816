const routeConstants = {
  START: "/",
  FOR_RESTAURANTS: "/dla-restauracji",
  FOR_RESTAURANTS_ALLOFFERS: "/dla-restauracji-oferty",
  FOR_RESTAURANTS_FORM: "/dla-restauracji-formularz",
  FOR_RESTAURANTS_SUCCESS: "/dla-restauracji-success",
  BLOG: "/blog",
  COVID: "/odpowiedzialnarestauracja",
  COVID_CLIENT: "/odpowiedzialnigoscie",
  ARTICLE_BLOG: "/article/:title/:id",
  FOR_GUESTS: "/dla-goscia",
  HOW_IT_WORKS: "/jak-to-dziala",
  PRICE_LIST: "/cennik",
  CONTACT: "/kontakt",
  DOCUMENTS: "/dokumenty",
  ABOUT: "/o-nas",
  RESTAURANT_PROFILE: "/restauracja/:name/:uuid/",
  ACCOUNT: "/konto",
  LOGIN: "/logowanie",
  LOGOUT: "/wylogowanie",
  REGISTRATION: "/rejestracja",
  RESERVATION: "/reservation",
  COOKIES_POLICY: "/polityka/cookies",
  PRIVACY_POLICY: "/polityka/prywatnosci",
  SEARCH: "/wyniki-wyszukiwania",
  SEARCH_FILTERS:
    "/wyniki-wyszukiwania-old/osob,:persons/lokalizacja,:location/data,:date/godzina,:hour/nazwa,:name/typ,:restaurantType/ocena,:rating/zakres-cenowy,:priceLevel,:event/wydarzenie,:product/produkt",
  SEARCH_MAP: "/wyniki-wyszukiwania-mapa",
  SEARCH_MAP_FILTERS:
    "/wyniki-wyszukiwania-mapa-old/osob,:persons/lokalizacja,:location/data,:date/godzina,:hour/nazwa,:name/typ,:restaurantType/ocena,:rating/zakres-cenowy,:priceLevel",
  RESERVATION_HISTORY: "/historia-rezerwacji",
  YOUR_ACCOUNT: "/konto",
  RESET_PASSWORD: "/reset-hasla",
  CHRISTMAS: "/oferta-swiateczna/:id?",
  SURVEY: "/survey/:uuid",
  _EN: {
    START: "/en",
    BLOG: "/en/blog",
    FOR_RESTAURANTS: "/en/for-restaurants",
    FOR_RESTAURANTS_ALLOFFERS: "/en/for-restaurants-offers",
    FOR_RESTAURANTS_FORM: "/en/for-restaurants-form",
    FOR_RESTAURANTS_SUCCESS: "/en/for-restaurants-success",
    COVID: "/en/covid-for-restaurants",
    COVID_CLIENT: "/en/covid-for-guests",
    FOR_GUESTS: "/en/for-guests",
    HOW_IT_WORKS: "/en/how-it-works",
    PRICE_LIST: "/en/price-list",
    CONTACT: "/en/contact",
    DOCUMENTS: "/en/documents",
    ABOUT: "/en/about",
    RESTAURANT_PROFILE: "/en/restaurant/:name/:uuid/",
    ACCOUNT: "/en/account",
    LOGIN: "/en/login",
    LOGOUT: "/en/logout",
    REGISTRATION: "/en/registration",
    COOKIES_POLICY: "/en/cookies-policy",
    PRIVACY_POLICY: "/en/privacy-policy",
    SEARCH: "/en/search",
    SEARCH_FILTERS:
      "/en/search-old/persons,:persons/location,:location/date,:date/hour,:hour/name,:name/restaurant-type,:restaurantType/rating,:rating/price-level,:priceLevel,:event/wydarzenie,:product/produkt",
    SEARCH_MAP: "/en/search-map",
    SEARCH_MAP_FILTERS:
      "/en/search-map-old/persons,:persons/location,:location/date,:date/hour,:hour/name,:name/restaurant-type,:restaurantType/rating,:rating/price-level,:priceLevel",
    RESERVATION_HISTORY: "/en/reservations-history",
    YOUR_ACCOUNT: "/en/account",
    RESET_PASSWORD: "/en/password-reset",
    RESERVATION: "/en/reservation",
    SURVEY: "/en/survey/:uuid",
  },
};

export default routeConstants;
