import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { routeService, requestService } from "../../services";
import "./LogoutPage.scss";

const LogoutPage = (props) => {
  const { t } = useTranslation();
  const currentLanguage =
    props.location.pathname.indexOf("/en") === 0 ? "en" : "pl";
  useEffect(() => {
    requestService.logout().then(
      (response) => {
        localStorage.removeItem("loggedUserData");
        localStorage.removeItem("facebookData");
        localStorage.removeItem("googleData");
        props.history.push(routeService.getRoute("LOGIN", currentLanguage));
      },
      () => {
        //...
      }
    );
  }, [props.history, currentLanguage]);

  return (
    <div>
      <Helmet>
        <title>{t("logoutPage.metadata.title")}</title>
        <meta
          name="description"
          content={t("logoutPage.metadata.description")}
        />
        <meta name="keywords" content={t("logoutPage.metadata.keywords")} />
      </Helmet>
    </div>
  );
};

export default withRouter(LogoutPage);
