const backendTranslationsPL = {
  ByWindow: "Przy oknie",
  Failed: "Nie powiodło się",
  ForHowManyPeopleTable: "Liczba nakryć",
  NewReservationCouldNotBeCreated: "Nie udało się utworzyć nowej rezerwacji",
  NewReservationHasBeenCreated: "Stworzono nową rezerwację",
  NonSmoking: "Dla niepalących",
  Outdoor: "Na zewnątrz",
  ReservationHasBeenUpdated: "Rezerwacja została zaktualizowana",
  "acceptance.required": "Zaakceptuj wymagane zgody",
  "account.changePassword": "Zmień hasło",
  "account.confirmRemoveAccount":
    "Czy na pewno chcesz usunąć konto? Wszystkie niezrealizowane rezerwacje zostaną automatycznie odwołane.",
  "account.removeAccount": "Usuń konto",
  "account.removeRequest": "Aby usunąć konto wprowadź hasło",
  "agreement.canRevert":
    "mojstolik.pl informuje o możliwości wycofania zgody na przetwarzanie danych osobowych.",
  "agreement.commercialInformationContact":
    "Wyrażam zgodę na przesyłanie przez Table4Rest sp. z o.o. informacji handlowej za pomocą środków komunikacji elektronicznej, w szczególności na podany przeze mnie numer telefonu oraz adres poczty elektronicznej, w tym SMS i MMS.",
  "agreement.marketingContact":
    "Wyrażam zgodę na kontaktowanie się ze mną przez Table4Rest sp. z o. o.,  w tym SMS i MMS, jaki i kontakt z użyciem telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących dla celów marketingu bezpośredniego, w tym na podany przeze mnie numer telefonu oraz adres poczty elektronicznej.",
  "agreement.marketingDataUsage":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celach marketingowych usług i produktów własnych oraz innych podmiotów, które są oferowane za pośrednictwem aplikacji MójStolik.",
  "agreement.personalData":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl",
  "agreement.readWithdrawal":
    "Zapoznałem się z pouczeniem o prawie odstąpienia od",
  "agreement.marketingAgreement":
    "Zgoda marketingowa",
  "promotion.readWithdrawal":
    "Zapoznałem się z regulaminem promocji, w szczególności z pouczeniem o prawie odstąpienia od umowy",
  "agreement.thirdPartDataUsage":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji.",
  "agreement.warningUncheck":
    "Odwołonie zgody uniemożliwia korzystanie z aplikacji. Aby ją odwołać, należy usunąć konto.",
  "api.connectionLost":
    "Połączenie z internetem zostało zerwane. Aplikacja zostanie przeładowana.",
  "app.readAgreement": "Regulamin usługi MojStolik.pl",
  "changePass.lowerLetter": "- zawierać małe litery",
  "changePass.newPass": "Nowe hasło",
  "changePass.numeric": "- zawierać cyfry",
  "changePass.oldPass": "Stare hasło",
  "changePass.passHaveToBe": "Hasło musi:",
  "changePass.passLength": "- mieć długość co najmniej 6 znaków",
  "changePass.specialChar": "- zawierać znak specjalny np. '#@!'",
  "changePass.upperLetter": "- zawierać co najmniej 1 dużą literę",
  "common.accept": "Zatwierdź",
  "common.add": "Dodaj",
  "common.alertCloseWindow": "Zamkniesz okno wyboru",
  "common.back": "Cofnij",
  "common.bankAccountNumber": "Numer konta bankowego",
  "common.buildingNumber": "Numer budynku",
  "common.cancel": "Anuluj",
  "common.changeButton": "Zmień",
  "common.city": "Miasto",
  "common.clear": "Wyczyść",
  "common.close": "Zamknij",
  "common.confirm": "Potwierdź",
  "common.confirmDecline": "Potwierdź/odrzuć",
  "common.couldNotDownloadData":
    "Nie udało się pobrać danych. Odśwież aplikację.",
  "common.dayShort.friday": "pt",
  "common.dayShort.monday": "pn",
  "common.dayShort.saturday": "so",
  "common.dayShort.sunday": "nd",
  "common.dayShort.thursday": "cz",
  "common.dayShort.tuesday": "wt",
  "common.dayShort.wednesday": "śr",
  "common.delete": "Usuń",
  "common.detailsButton": "Szczegóły",
  "common.error": "Błąd",
  "common.gr": "gr",
  "common.help": "Pomoc",
  "common.hourShort": "godz.",
  "common.localNumber": "Numer lokalu",
  "common.name": "Nazwa",
  "common.new": "Nowy",
  "common.next": "Dalej",
  "common.nextButton": "Dalej",
  "common.noData": "Brak danych do wyświetlenia",
  "common.noValue": "Nie",
  "common.ok": "Ok",
  "common.password": "Hasło",
  "common.preferences": "Preferencje",
  "common.requiredInputError": "Pole jest wymagane",
  "common.save": "Zapisz",
  "common.search": "Szukaj",
  "common.send": "Wyślij",
  "common.shortPersons": "{{persons}} os.",
  "common.street": "Ulica",
  "common.successful": "Sukces",
  "common.undoButton": "Cofnij",
  "common.unit": "Jednostka",
  "common.vatId": "NIP",
  "common.yesValue": "Tak",
  "common.zipCode": "Kod pocztowy",
  "common.zl": "zł",
  "company.addNewAction": "Dodano nową firmę",
  "company.addNewCompany": "Dodać nową  firmę?",
  "company.addressData": "Dane adresowe",
  "company.buildingNumber": "Numer budynku",
  "company.city": "Miasto",
  "company.companyExistsError": "Firma już istnieje",
  "company.country": "Kraj",
  "company.localNumber": "Numer lokalu",
  "company.name": "Nazwa firmy",
  "company.nipNumber": "NIP",
  "company.postalCode": "Kod pocztowy",
  "company.questionUpdateModal": "Zaktualizować firmę?",
  "company.street": "Ulica",
  "company.updateAction": "Firma została zaktualizowana",
  "company.updateCompany": "Zaktualizować istniejącą firmę?",
  "company.vatId": "NIP",
  "config.communication.confirmationReservation": "Potwierdzenie rezerwacji",
  "config.communication.modificationReservation": "Zmiana rezerwacji",
  "config.communication.notifTitle": "Powiadomienia PUSH",
  "config.communication.rejectionReservation": "Odwołanie rezerwacji",
  "config.communication.remindNotification": "Przypomnienia o rezerwacji",
  "config.navbar.account": "Konto",
  "config.navbar.communication": "Komunikacja",
  "config.navbar.configuration": "Konfiguracja",
  "config.navbar.myaccount": "Konto",
  "config.navbar.mypreferences": "Preferencje",
  "configuration.customerProfiles": "Profile Klientów",
  "configuration.employeeGroups": "Grupy pracowników",
  "configuration.help": "Pomoc",
  "configuration.restaurant": "Restauracja",
  "cuisine.arabic": "Kuchnia arabska",
  "cuisine.romania": "Kuchnia rumuńska",
  "customer.accountDetails": "Szczegóły konta",
  "customer.addEdit": "Dodaj / Edytuj",
  "customer.blocked":
    "Konto zostało zablokowane. Możliwość rezerwacji została zablokowana.",
  "customer.confirmText": "Rezerwuj",
  "customer.createInvoice": "Stwórz fakturę",
  "customer.createNewProfile": "Dodano nowy profil",
  "customer.createNewProfileModal": "Utworzyć nowy profil?",
  "customer.deleteProfile": "Usuń profil",
  "customer.description": "Opis",
  "customer.editCompany": "Edytuj firmę",
  "customer.editProfile": "Zaktualizowano profil",
  "customer.editReservingCustomerData": "Edytuj dane osoby rezerwującej",
  "customer.emailAddress": "E-mail",
  "customer.errorProfileAction": "Profil jest przypisany do klienta",
  "customer.existError": "Użytkownik już istnieje",
  "customer.firstCreateCompany": "*najpierw wybierz firmę",
  "customer.groupChooseLabel": "Wybierz",
  "customer.labels.blocked": "Zablokowany",
  "customer.labels.customerName": "Nazwa",
  "customer.labels.emailLabel": "Email",
  "customer.labels.firstNameLabel": "Imię",
  "customer.labels.groupLabel": "Grupa",
  "customer.labels.lastNameLabel": "Nazwisko",
  "customer.labels.phoneNumber": "Telefon",
  "customer.labels.statusLabel": "Status",
  "customer.listOfProfiles": "Lista profili",
  "customer.mail": "E-mail",
  "customer.name": "Imię",
  "customer.newPasswordWasSent": "Nowe hasło zostało wysłane w wiadomości SMS",
  "customer.noCustomer": "Brak użytkownika",
  "customer.noCustomers": "Brak klientów",
  "customer.noReservations": "Brak rezerwacji",
  "customer.onePersonFound": "Znaleziono 1 osobę",
  "customer.password": "Hasło",
  "customer.password.mismatch": "Stare hasło nie pasuje",
  "customer.passwordHasChange": "Hasło zostało zmienione",
  "customer.permissionForHoldingPersonalData":
    "Zgoda na przetwarzanie danych osobowych",
  "customer.profile": "Profil",
  "customer.profileDetails": "Szczegóły profilu",
  "customer.profileExistsError": "Profil już istnieje",
  "customer.registration.code": "Twój kod aktywacyjny to: _CODE_",
  "customer.registration.invalid": "Niepoprawny kod aktywacyjny",
  "customer.registration.sendByEmail":
    "Problem z wysłaniem SMS - kod został wysłany na podany email adres.",
  "customer.remarks": "Uwagi",
  "customer.remarksToClient": "Uwagi do klienta",
  "customer.remarksToReservation": "Uwagi do rezerwacji",
  "customer.removeProfileAction": "Usunięto profil",
  "customer.removeProfileAction.error": "Profil nie został usunięty",
  "customer.reservationHistory": "Historia rezerwacji",
  "customer.reservingCustomerData": "Dane osoby rezerwującej",
  "customer.saveChanges": "Zapisać zmiany?",
  "customer.surname": "Nazwisko",
  "customer.telephone": "Telefon",
  "customer.telephoneNumber": "Numer telefonu",
  "customer.updateClient": "Zaktualizować dane klienta?",
  "customer.updateProfileModal": "Zaktualizować profil?",
  "editReservation.send": "Wyślij",
  "editReservation.sendMessage":
    "Wysłano prośbę o zmianę parametrów rezerwacji.",
  "email.already.exist": "Email już istnieje",
  error: "Wystąpił błąd. Spróbuj ponownie później.",
  "error.cannotCreateReservation": "Nie udało się wysłać prośby",
  "error.connectionWithServerLost": "Utracono połączenie z serwerem.",
  "error.connectionWithServerLostTryLater":
    "Utracono połączenie z serwerem. Spróbuj ponownie później",
  "error.email.already.exist": "Email już istnieje",
  "error.facebook.account.already.exist":
    "Na ten numer jest już założone konto w MojStolik przez Facebook. Użyj logowanie przez Facebook",
  "error.google.account.already.exist":
    "Na ten numer jest już założone konto w MojStolik przez Google. Użyj logowanie przez Google",
  "error.notFoundAddress": "Nie znaleziono lokalizacji",
  "error.phone.already.exist":
    "Na ten numer jest już założone konto w MojStolik. Zaloguj się poprzez numer telefonu, lub użyj opcji przypomnij hasło.",
  "filters.doFilter": "Filtruj",
  "filters.fromToday": "Od dzisiaj",
  "filters.toToday": "Do dzisiaj",
  "filters.today": "Dzisiaj",
  "footer.Next": "Następne",
  "footer.Previous": "Poprzednie",
  "footer.alertOnBack": "Porzucić zmiany?",
  "group.removeErrorAction": "Grupa jest przypisana do użytkownika",
  "image.notFound": "Obrazka nie znaleziono",
  "invoice.vatId": "NIP: {{vatId}}",
  login: "Login",
  "login.accountDoesNotExist": "Konto nie istnieje",
  "login.accountName": "Nazwa użytkownika",
  "login.doLogin": "Zaloguj",
  "login.forgotPassword": "Nie pamiętasz hasła?",
  "login.forgottenPasswordLink": "Zapomniałeś hasło?",
  "login.generalTitle": "Logowanie",
  "login.insertLoginData": "Wprowadź dane logowania",
  "login.insertManagerPassword": "Wprawadź adres email menadżera",
  "login.loggingToSystem": "Logowanie do systemu",
  "login.loginOrPasswordWrong": "Login lub hasło nieprawidłowe",
  "login.loginText": "Zaloguj",
  "login.logout": "Wyloguj",
  "login.logout.message": "Zostałeś wylogowany",
  "login.newAccount": "Nowe konto",
  "login.newUpdateReady": "Jest dostępna nowa wersja aplikacji. Zaktualizuj.",
  "login.passwordRecovery": "Odzyskiwanie hasła",
  "login.passwordSent.sms": "Twoje nowe hasło to: _PASSWORD_",
  "login.passwordSent.sms.too.often":
    "Zbyt częsta próba zmiana hasła, spróbu za chwilę",
  "login.passwordSentToEmail":
    "Nowe hasło zostało wysłane na podany adres e-mail",
  "login.phoneNumberOrMail": "Nr telefonu, lub adres e-mail",
  "login.recover": "Odzyskaj",
  "login.resetPasswordLabel": "Adres e-mail",
  "login.restaurantChanged": "Restauracja została zmieniona",
  "login.thisAccountAlreadyExist": "Użytkownik już istnieje",
  "login.updateRequired":
    "Musisz zaktualizować aplikację. Może nie działać poprawnie",
  "login.userUnauthorized": "Login lub hasło nieprawidłowe",
  "login.userUnauthorized.useFacebook":
    "Zły login lub hasło. Spróbuj zalogować się przez Facebook lub zresetuj hasło",
  "login.userUnauthorized.useGoogle":
    "Zły login lub hasło. Spróbuj zalogować się przez Google lub zresetuj hasło.",
  "login.yourPassword": "Twoje hasło",
  "map.searchArea": "Przeszukaj obszar",
  "menu.areaControl": "Zarządzanie salami",
  "menu.config": "Konfiguracja",
  "menu.configPage": "Ustawienia",
  "menu.configuration": "Konfiguracja",
  "menu.currentReservations": "Rezerwacje bieżące",
  "menu.customers": "Klienci",
  "menu.homePage": "Główna",
  "menu.invoice": "Faktury",
  "menu.login": "Logowanie",
  "menu.logout": "Wyloguj",
  "menu.myReserv": "Rezerwacje",
  "menu.myReservations": "Moje rezerwacje",
  "menu.newAccount": "Nowe konto",
  "menu.passwordRecovery": "Odzyskanie hasła",
  "menu.reservationpage": "Nowa rezerwacja",
  "menu.reservations": "Wszystkie rezerwacje",
  "menu.resetPassword": "Przypomnienie hasła",
  "menu.restaurant": "Restauracja",
  "menu.sale": "Promocje",
  "menu.search": "Szukaj",
  "myReservation.doRate": "Oceń",
  "myReservation.doRateQuestion":
    "Czy chcesz ocenić tą rezerwację na {{rate}}/5?",
  "myReservation.doRateSuccess": "Ocena została wystawiona",
  "myReservation.doRateTitle": "Jak oceniasz swoją rezerwację?",
  "myReservation.doSuccessRemove": "Rezerwacja została usunięta z listy",
  "myReservation.yourRate": "Twoja ocena",
  "navbar.allReserv": "Wszystkie",
  "navbar.areaEdition": "Edycja sali",
  "navbar.cancelChangesReservation": "Anulować zmianę rezerwacji?",
  "navbar.cancelReservation": "Anulować rezerwację?",
  "navbar.changePass": "Zmiana hasła",
  "navbar.clientPreview": "Podgląd klienta",
  "navbar.companyEdition": "Edycja firmy",
  "navbar.configuration": "Konfiguracja",
  "navbar.currentReserv": "Bieżące",
  "navbar.currentReservations": "Rezerwacje bieżące",
  "navbar.customers": "Klienci",
  "navbar.dataCustomer": "Dane osoby rezerwującej",
  "navbar.defaultCancel": "Anulować?",
  "navbar.employee": "Pracownicy",
  "navbar.historyReserv": "Historyczne",
  "navbar.invoiceEdition": "Edycja faktury",
  "navbar.invoicePreview": "Podgląd faktur",
  "navbar.label.editReservation": "Zmiana rezerwacji",
  "navbar.label.myProfile": "Mój profil",
  "navbar.label.myReservation": "Moje rezerwacje",
  "navbar.label.reservation": "Rezerwacja",
  "navbar.label.reservationDetails": "Szczegóły rezerwacji",
  "navbar.label.sale": "Promocje",
  "navbar.newReservation": "Nowa rezerwacja",
  "navbar.reservationActualState": "Aktualny stan rezerwacji",
  "navbar.reservationEdit": "Edycja rezerwacji",
  "navbar.reservationPreview": "Podgląd rezerwacji",
  "navbar.reservations": "Rezerwacje",
  "notification.body.Canceled":
    "Twoja rezerwacja w  _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została odwołana.",
  "notification.body.Completed":
    "Dziękujemy za realizację rezerwacji w _RESTAURACJA_ w _DAY_ o godz: _TIME_.",
  "notification.body.Confirmed":
    "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została potwierdzona.",
  "notification.body.Rejected":
    "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została odwołana. _MESSAGE_",
  "notification.body.WaitingForApproval":
    "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ czeka na zatwierdzenie.",
  "notification.body.WaitingForConfirmation":
    "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ czeka na potwierdzenie.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Wiadomość od _RESTAURANT_",
  "notification.parameters.updated.body":
    "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została zaktualizowana.",
  "notification.parameters.updated.title": "Aktualizajca rezerwacji",
  "password.errors.weak": "Hasło jest za proste",
  "payment.card": "karta",
  "payment.cash": "gotówka",
  "payment.otherType": "inny",
  "payment.transfer": "przelew",
  "payment.type": "Sposób płatności",
  "preference.category.cuisine": "Rodzaj Kuchni",
  "preference.category.cuisine.polish": "Kuchnie Polska",
  "preference.category.price": "Cena",
  "promotions.emptyList": "Brak promocji",
  "registration.activeCode": "Kod aktywacyjny",
  "registration.addressEmail": "Adres e-mail",
  "registration.codeInformation":
    "Na numer {{phone}} został przesłany kod aktywacyjny wprowadź go poniżej i zatwierdź przyciskiem",
  "registration.createAccount": "Utwórz konto",
  "registration.createSuccess": "Konto zostało utworzone",
  "registration.differentPasswords": "Hasła róźnią się",
  "registration.finishButton": "Zakończ rejestrację",
  "registration.formIsInvalid":
    "Dane nie zostały poprawnie wypełnione. Skorzystaj z pomocy na dole.",
  "registration.generalTitle": "Rejestracja",
  "registration.phoneNumber": "Numer telefonu",
  "registration.phoneToShort": "Numer jest za krótki",
  "registration.readAgreement": "Przeczytaj regulamin",
  "registration.repeatPassword": "Powtórz hasło",
  "registration.requiredFieldsFirstStep":
    "Numer telefonu, e-mail oraz hasło są obowiązkowe.",
  "registration.weakPassword": "Hasło jest za proste",
  "request.blocked": "Zapytanie zostało zablokowane, spróbuj później",
  "reservation.addNewAction": "Stworzono nową rozerwację",
  "reservation.addNote": "Dodaj uwagę",
  "reservation.addRemarkQuestion": "Dodać uwagę do rezerwacji?",
  "reservation.alreadyRated": "Rezerwacja była już oceniona",
  "reservation.basicInformation": "Podstawowe informacje",
  "reservation.cancelReservation": "Odwołaj",
  "reservation.cancelReservationQuestion":
    "Czy na pewno chcesz odwołać rezerwację?",
  "reservation.changeReservation": "Zmień",
  "reservation.changeReservationParameters": "Zmień parametry rezerwacji",
  "reservation.changeStatusAction": "Zmieniono status na",
  "reservation.chooseStatus": "Wybierz status",
  "reservation.comunications": "Wiadomości",
  "reservation.createReservation": "Utworzyć rezerwację na",
  "reservation.defaultTime": "Preferowany czas rezerwacji {{reservationTime}}.",
  "reservation.doReservation": "Rezerwuj",
  "reservation.duration": "Czas trwania",
  "reservation.durationTime": "Czas trwania rezerwacji {{durationTime}}",
  "reservation.editData": "Edytuj dane",
  "reservation.editReservation": "Edytuj rezerwację",
  "reservation.errorNewAction": "Nie udało się utworzyć nowej rezerwacji",
  "reservation.errorPast": "Nie można utworzyć rezerwacji w przeszłości",
  "reservation.filter.fromDate": "Od kiedy",
  "reservation.filter.lastName": "Nazwisko",
  "reservation.filter.toDate": "Do kiedy",
  "reservation.forHowLong": "Czas trwania",
  "reservation.generalTitle": "Rezerwacja",
  "reservation.group": "Grupowa",
  "reservation.individual": "Indywidualna",
  "reservation.informationOnReservation": "Informacje o rezerwacji",
  "reservation.labels.client": "Klient",
  "reservation.labels.date": "Termin",
  "reservation.labels.dateTo": "Do",
  "reservation.labels.firstName": "Imie",
  "reservation.labels.lastName": "Nazwisko",
  "reservation.labels.number": "Nr rez.",
  "reservation.labels.numberOfPeople": "Liczba gości",
  "reservation.labels.phone": "Telefon",
  "reservation.labels.profil": "Profil",
  "reservation.labels.remarks": "Uwagi",
  "reservation.labels.status": "Status",
  "reservation.labels.tableNumber": "Sala - stolik",
  "reservation.lessSpotThanPeople":
    "Wybrano mniejszą ilość miejsc. Kontynuować?",
  "reservation.message": "Wiadomość od MojStolik.pl",
  "reservation.messages": "Napisz wiadomość",
  "reservation.morePeople": "Większa liczba osób:",
  "reservation.newPersonToReservation": "Dodać nową osobę do rezerwacji?",
  "reservation.noFreeTables": "Brak wolnych stolików",
  "reservation.notExist": "Rezerwacja nie istnieje",
  "reservation.numberOfGuests": "Liczba gości",
  "reservation.personsError": "Nie można wysłać prośby o rezerwację dla 0 osób",
  "reservation.pickDurationTime": "Wybierz czas trwania",
  "reservation.preferences": "Preferencje",
  "reservation.remarks": "Uwagi",
  "reservation.remarksFromRestaurant": "Komentarz restauracji",
  "reservation.remind.message":
    "Serwis MojStolik.pl przypomina o rezerwacji w restauracji _RESTAURANT_ w terminie _DATE_ dla _PERSONS_. W celu odwołania rezerwacji kliknij w link: _LINK_ Usługa rezerwacji realizowana jest przez serwis MojStolik.pl",
  "reservation.request.not.yet.available": "Rezerwacje jeszcze nie dostępna.",
  "reservation.request.too.often": "Prośba właśnie wysłana",
  "reservation.reservationDate": "Dzień",
  "reservation.spotsChosen": "Wybrane miejsca",
  "reservation.status": "Status",
  "reservation.status.Canceled": "Odwołana",
  "reservation.status.Completed": "Rezerwacja zrealizowana",
  "reservation.status.Confirmed": "Rezerwacja została potwierdzona",
  "reservation.status.Delayed": "Opóźniona",
  "reservation.status.NotCompleted": "Rezerwacja niezrealizowana",
  "reservation.status.Rejected": "Rezerwacja została anulowana",
  "reservation.status.WaitingForApproval":
    "Rezerwacja oczekuje na zatwierdzenie",
  "reservation.status.WaitingForConfirmation": "Oczekująca",
  "reservation.successRequest": "Prośba o rezerwację została wysłana",
  "reservation.updateAction": "Rezerwacja została zaktualizowana",
  "reservation.updateReservation": "Zaktualizować rezerwację?",
  "reservation.updateReservationButton": "Aktualizuj rezerwację",
  "reservation.whichHour": "Godzina",
  "reservation.wholeRoom": "Cała sala",
  "reservationMenu.allActive": "Wszystkie aktywne",
  "reservationMenu.fromApp": "Zapytania z aplikacji",
  "reservationMenu.toConfirmation": "Do potwierdzenia",
  "reservationMenu.today": "Na dzisiaj",
  "reservationParams.restaurantName": "Nazwa lokalu",
  "reservationParams.yourLocalization": "Twoja lokalizacja",
  "reservations.noReservations": "Brak rezerwacji",
  "resetPassoword.generalTitle": "Przypomnienie hasła",
  "resetPassoword.info":
    "Podaj numer telefonu, na który zostało zarejestrowane konto. Hasło zostanie wysłane SMS-em na ten numer",
  "resetPassoword.sendNewPassword": "Wyślij hasło",
  "restaurant.aboutUs": "O nas",
  "restaurant.bankAccountNumber": "Numer konta bankowego",
  "restaurant.buildingNumber": "Numer budynku",
  "restaurant.city": "Miasto",
  "restaurant.closeHour": "Godzina zamknięcia",
  "restaurant.companyName": "Nazwa firmy",
  "restaurant.dayFormat": "dd - dzień miesiąca",
  "restaurant.defaultReservationLength": "Domyślna długość rezerwacji (h)",
  "restaurant.emailAddress": "E-mail",
  "restaurant.emptyList":
    "Nie ma restauracji spełniających podane kryteria wyszukiwania.",
  "restaurant.greaterThenZeroError": "Musi być większe od 0",
  "restaurant.insertRestaurant": "Wprowadź restauracje",
  "restaurant.legend": "Legenda:",
  "restaurant.localNumber": "Numer lokalu",
  "restaurant.logo": "Logo",
  "restaurant.maximalReservationLength": "Maksymalna długość rezerwacji (h)",
  "restaurant.menu.price": "{{price}} zł",
  "restaurant.minimalPeopleForGroup":
    "Minimalna liczba osób na rezerwację grupową",
  "restaurant.monthFormat": "MM - miesiąc",
  "restaurant.navbar.aboutRestaurant": "Restauracja",
  "restaurant.navbar.menu": "Menu",
  "restaurant.navbar.promotion": "Promocje",
  "restaurant.navigate": "Nawiguj do restauracji",
  "restaurant.navigationApp": "Aplikacja do nawigacji",
  "restaurant.nipNumber": "NIP",
  "restaurant.openHour": "Godzina Otwarcia",
  "restaurant.pickDay": "Wybierz dzień",
  "restaurant.pickHour": "Wybierz godzinę",
  "restaurant.pickPersons": "Wybierz liczbę osób",
  "restaurant.postalCode": "Kod pocztowy",
  "restaurant.price.expensive": "Bardzo drogo",
  "restaurant.price.extralow": "Bardzo tanio",
  "restaurant.price.low": "Tanio",
  "restaurant.price.medium": "Nie tak drogo",
  "restaurant.productsServices": "Produkty / usługi",
  "restaurant.promotion.label": "Promocje",
  "restaurant.questionUpdateRestaurant": "Zaktualizować dane o restauracji?",
  "restaurant.rate": "Ocena",
  "restaurant.restaurant": "Restauracja",
  "restaurant.saveNavigationPick": "Zapamiętaj wybór",
  "restaurant.seriesNumberTemplate": "Szablon numeracji faktur",
  "restaurant.serverAddress": "Serwer adres",
  "restaurant.street": "Ulica",
  "restaurant.web.confirm": "Czy chcesz otworzyć stronę www?",
  "restaurant.writeCity": "Miasto",
  "restaurant.yearEndFormat": "uu - rok końcówka np. 18",
  "restaurant.yearFullFormat": "uuuu - pełen rok np. 2018",
  "restaurantPreview.doReservation": "Zarezerwuj",
  "room.firstRoomVisualization": "Najpierw stwórz wizualizację sali",
  "sms.send.problem":
    "Problem z wysłaniem wiadomości SMS, spróbuj proszę przez aplikację",
  "social.fbloginBtn": "Zaloguj się",
  "social.googleloginBtn": "Zaloguj się",
  "status.Canceled": "Odwołana",
  "status.Confirmed": "Potwierdzona",
  "status.Rejected": "Odrzucona",
  "status.WaitingForConfirmation": "Oczekuje na potwierdzenie",
  "token.expired": "Kod wygasł",
  "token.wrong": "Kod nie zgadza się lub wygasł",
  "user.acceptAgreement": "Akceptuję regulamin usługi www.mojstolik.pl",
  "user.addNewAction": "Dodano klienta",
  "user.cityAddress": "Miejscowość",
  "user.dateOfBirth": "Data urodzenia",
  "user.editAction": "Zaktualizowano klienta",
  "user.email": "E-mail",
  "user.firstName": "Imię",
  "user.lastName": "Nazwisko",
  "user.name": "Imię",
  "user.phone": "Telefon",
  "user.postalCode": "Kod pocztowy",
  "user.sessionExpire": "Twoja sesja wygasła",
  "user.streetAddress": "Adres",
  "user.successUpdate": "Dane osobowe zostały zaktualizowane",
  "user.surname": "Nazwisko",
  "user.updateQuestion": "Czy chcesz zaktualizować dane osobowe?",
  "validation.fieldRequired": "To pole jest wymagane",
  "validation.passwordRequired": "Hasło jest wymagane",
  "validation.phoneRequired": "Numer telefonu jest wymagany",
  "reservationHistoryPage.sendMessage": "Wyślij wiadomość",
  "reservationHistoryPage.send": "Wyślij",
  "reservationHistoryPage.cancel": "Anuluj",
  "reservationChatPage.send": "Wyślij",
  'reservationChatPage.writeMessage': 'Wpisz wiadomość',
  "reservationHistoryPage.paragraph.invoice": "Faktura",
};

export default backendTranslationsPL;
