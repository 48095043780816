import React from "react";
import { routeConstants } from "../constants";
import IndexPage from "../pages/indexPage/IndexPage";
//import AccountPage from "../pages/accountPage/AccountPage";
//import ReservationHistoryPage from "../pages/reservationHistoryPage/ReservationHistoryPage";
//import PrivacyPolicyPage from "../pages/privacyPolicyPage/PrivacyPolicyPage";
//import CookiesPolicyPage from "../pages/cookiesPolicyPage/CookiesPolicyPage";
//import ForRestaurantsPage from "../pages/forRestaurantsPage/ForRestaurantsPage";
//import ForGuestPage from "../pages/forGuestPage/ForGuestPage";
//import HowItWorksPage from "../pages/howItWorksPage/HowItWorksPage";
//import PriceListPage from "../pages/priceListPage/PriceListPage";
//import ContactPage from "../pages/contactPage/ContactPage";
//import DocumentsPage from "../pages/documentsPage/DocumentsPage";
//import AboutPage from "../pages/aboutPage/AboutPage";
//import RestaurantProfilePage from "../pages/restaurantProfilePage/RestaurantProfilePage";
//import LoginPage from "../pages/loginPage/LoginPage";
import LogoutPage from "../pages/logoutPage/LogoutPage";
//import RegistrationPage from "../pages/registrationPage/RegistrationPage";
//import SearchPage from "../pages/searchPage/SearchPage";
//import SearchMapPage from "../pages/searchMapPage/SearchMapPage";
//import ResetPasswordPage from "../pages/resetPasswordPage/ResetPasswordPage";
import NotFoundPage from "../pages/notFoundPage/NotFoundPage";

//const IndexPage = React.lazy(() => import('../pages/indexPage/IndexPage'));
const AccountPage = React.lazy(() =>
  import("../pages/accountPage/AccountPage")
);
const ReservationHistoryPage = React.lazy(() =>
  import("../pages/reservationHistoryPage/ReservationHistoryPage")
);

const PrivacyPolicyPage = React.lazy(() =>
  import("../pages/privacyPolicyPage/PrivacyPolicyPage")
);
const CookiesPolicyPage = React.lazy(() =>
  import("../pages/cookiesPolicyPage/CookiesPolicyPage")
);
const ForRestaurantsPage = React.lazy(() =>
  import("../pages/forRestaurantsPage/ForRestaurantsPage")
);
const ForRestaurantsPageAllOffers = React.lazy(() =>
  import(
    "../pages/forRestaurantsPage/forRestaurantsPageAllOffers/ForRestaurantsPageAllOffers"
  )
);
const ForRestaurantsPageForm = React.lazy(() =>
  import(
    "../pages/forRestaurantsPage/forRestaurantsPageForm/ForRestaurantsPageForm"
  )
);
const ForRestaurantsPageSuccess = React.lazy(() =>
  import("../pages/forRestaurantsPage/ForRestaurantsPageSuccess")
);
const RestaurantCovid = React.lazy(() =>
  import("../pages/forRestaurantsPage/RestaurantCovid")
);
const ClientCovid = React.lazy(() =>
  import("../pages/forRestaurantsPage/ClientCovid")
);
const Christmas = React.lazy(() =>
  import("../pages/forRestaurantsPage/Christmas")
);
const ForGuestPage = React.lazy(() =>
  import("../pages/forGuestPage/ForGuestPage")
);
const HowItWorksPage = React.lazy(() =>
  import("../pages/howItWorksPage/HowItWorksPage")
);
const PriceListPage = React.lazy(() =>
  import("../pages/priceListPage/PriceListPage")
);
const ContactPage = React.lazy(() =>
  import("../pages/contactPage/ContactPage")
);
const DocumentsPage = React.lazy(() =>
  import("../pages/documentsPage/DocumentsPage")
);
const AboutPage = React.lazy(() => import("../pages/aboutPage/AboutPage"));
const RestaurantProfilePage = React.lazy(() =>
  import("../pages/restaurantProfilePage/RestaurantProfilePage")
);
const LoginPage = React.lazy(() => import("../pages/loginPage/LoginPage"));
const RegistrationPage = React.lazy(() =>
  import("../pages/registrationPage/RegistrationPage")
);
const SearchPage = React.lazy(() => import("../pages/searchPage/SearchPage"));
const SearchMapPage = React.lazy(() =>
  import("../pages/searchMapPage/SearchMapPage")
);
const ResetPasswordPage = React.lazy(() =>
  import("../pages/resetPasswordPage/ResetPasswordPage")
);
const BlogPage = React.lazy(() => import("../pages/blogPage/BlogPage"));
const ArticlePage = React.lazy(() => import("../pages/blogPage/ArticlePage"));
const ReservationPage = React.lazy(() =>
  import("../pages/reservationPage/ReservationPage")
);
const SurveyPage = React.lazy(() => import("../pages/surveyPage/SurveyPage"));

const routes = [
  /* PL */
  {
    uuid: "index",
    path: "/",
    exact: true,
    component: IndexPage,
  },
  {
    uuid: "reset_password",
    path: routeConstants["RESET_PASSWORD"],
    component: ResetPasswordPage,
  },
  {
    uuid: "account",
    path: routeConstants["YOUR_ACCOUNT"],
    component: AccountPage,
  },
  {
    uuid: "reservation_history",
    path: routeConstants["RESERVATION_HISTORY"],
    component: ReservationHistoryPage,
  },
  {
    uuid: "privacy_policy",
    path: routeConstants["PRIVACY_POLICY"],
    component: PrivacyPolicyPage,
  },
  {
    uuid: "cookies_policy",
    path: routeConstants["COOKIES_POLICY"],
    component: CookiesPolicyPage,
  },
  {
    uuid: "for_restaurants",
    path: routeConstants["FOR_RESTAURANTS"],
    component: ForRestaurantsPage,
  },
  {
    uuid: "for_restaurants_alloffers",
    path: routeConstants["FOR_RESTAURANTS_ALLOFFERS"],
    component: ForRestaurantsPageAllOffers,
  },
  {
    uuid: "for_restaurants_form",
    path: routeConstants["FOR_RESTAURANTS_FORM"],
    component: ForRestaurantsPageForm,
  },
  {
    uuid: "for_restaurants_success",
    path: routeConstants["FOR_RESTAURANTS_SUCCESS"],
    component: ForRestaurantsPageSuccess,
  },
  {
    uuid: "blog",
    path: routeConstants["BLOG"],
    component: BlogPage,
  },
  {
    uuid: "chistmas",
    path: routeConstants["CHRISTMAS"],
    component: Christmas,
  },
  {
    uuid: "covid",
    path: routeConstants["COVID"],
    component: RestaurantCovid,
  },
  {
    uuid: "covid-client",
    path: routeConstants["COVID_CLIENT"],
    component: ClientCovid,
  },
  {
    uuid: "article_blog",
    path: routeConstants["ARTICLE_BLOG"],
    component: ArticlePage,
  },
  {
    uuid: "for_gursts",
    path: routeConstants["FOR_GUESTS"],
    component: ForGuestPage,
  },
  {
    uuid: "how_it_works",
    path: routeConstants["HOW_IT_WORKS"],
    component: HowItWorksPage,
  },
  {
    uuid: "price_list",
    path: routeConstants["PRICE_LIST"],
    component: PriceListPage,
  },
  {
    uuid: "contact",
    path: routeConstants["CONTACT"],
    component: ContactPage,
  },
  {
    uuid: "documents",
    path: routeConstants["DOCUMENTS"],
    component: DocumentsPage,
  },
  {
    uuid: "about",
    path: routeConstants["ABOUT"],
    component: AboutPage,
  },
  {
    uuid: "restaurant_profile",
    path: routeConstants["RESTAURANT_PROFILE"],
    component: RestaurantProfilePage,
  },
  {
    uuid: "searchFilters",
    path: routeConstants["SEARCH_FILTERS"],
    component: SearchPage,
  },
  {
    uuid: "search",
    path: routeConstants["SEARCH"],
    component: SearchPage,
    exact: true,
  },
  {
    uuid: "searchMapFilters",
    path: routeConstants["SEARCH_MAP_FILTERS"],
    component: SearchMapPage,
  },
  {
    uuid: "searchMap",
    path: routeConstants["SEARCH_MAP"],
    component: SearchMapPage,
    exact: true,
  },
  {
    uuid: "login",
    path: routeConstants["LOGIN"],
    component: LoginPage,
  },
  {
    uuid: "logout",
    path: routeConstants["LOGOUT"],
    component: LogoutPage,
  },
  {
    uuid: "registration",
    path: routeConstants["REGISTRATION"],
    component: RegistrationPage,
  },
  {
    uuid: "reservation",
    path: routeConstants["RESERVATION"],
    component: ReservationPage,
  },
  {
    uuid: "survey",
    path: routeConstants["SURVEY"],
    component: SurveyPage,
  },
  /* EN */
  {
    uuid: "index_en",
    path: "/en",
    exact: true,
    component: IndexPage,
  },
  {
    uuid: "blog",
    path: routeConstants["_EN"]["BLOG"],
    component: BlogPage,
  },
  {
    uuid: "reset_password_en",
    path: routeConstants["_EN"]["RESET_PASSWORD"],
    component: ResetPasswordPage,
  },
  {
    uuid: "account_en",
    path: routeConstants["_EN"]["YOUR_ACCOUNT"],
    component: AccountPage,
  },
  {
    uuid: "covid_en",
    path: routeConstants["_EN"]["COVID"],
    component: RestaurantCovid,
  },
  {
    uuid: "covid-client_en",
    path: routeConstants["_EN"]["COVID_CLIENT"],
    component: ClientCovid,
  },
  {
    uuid: "reservation_history_en",
    path: routeConstants["_EN"]["RESERVATION_HISTORY"],
    component: ReservationHistoryPage,
  },
  {
    uuid: "privacy_policy_en",
    path: routeConstants["_EN"]["PRIVACY_POLICY"],
    component: PrivacyPolicyPage,
  },
  {
    uuid: "cookies_policy_en",
    path: routeConstants["_EN"]["COOKIES_POLICY"],
    component: CookiesPolicyPage,
  },
  {
    uuid: "for_restaurants_en",
    path: routeConstants["_EN"]["FOR_RESTAURANTS"],
    component: ForRestaurantsPage,
  },
  {
    uuid: "for_restaurants_alloffers_en",
    path: routeConstants["_EN"]["FOR_RESTAURANTS_ALLOFFERS"],
    component: ForRestaurantsPageAllOffers,
  },
  {
    uuid: "for_restaurants_form_en",
    path: routeConstants["_EN"]["FOR_RESTAURANTS_FORM"],
    component: ForRestaurantsPageForm,
  },
  {
    uuid: "for_restaurants_success_en",
    path: routeConstants["_EN"]["FOR_RESTAURANTS_SUCCESS"],
    component: ForRestaurantsPageSuccess,
  },
  {
    uuid: "for_gursts_en",
    path: routeConstants["_EN"]["FOR_GUESTS"],
    component: ForGuestPage,
  },
  {
    uuid: "how_it_works_en",
    path: routeConstants["_EN"]["HOW_IT_WORKS"],
    component: HowItWorksPage,
  },
  {
    uuid: "price_list_en",
    path: routeConstants["_EN"]["PRICE_LIST"],
    component: PriceListPage,
  },
  {
    uuid: "contact_en",
    path: routeConstants["_EN"]["CONTACT"],
    component: ContactPage,
  },
  {
    uuid: "documents_en",
    path: routeConstants["_EN"]["DOCUMENTS"],
    component: DocumentsPage,
  },
  {
    uuid: "about_en",
    path: routeConstants["_EN"]["ABOUT"],
    component: AboutPage,
  },
  {
    uuid: "restaurant_profile_en",
    path: routeConstants["_EN"]["RESTAURANT_PROFILE"],
    component: RestaurantProfilePage,
  },
  {
    uuid: "searchFilters_en",
    path: routeConstants["_EN"]["SEARCH_FILTERS"],
    component: SearchPage,
  },
  {
    uuid: "search_en",
    path: routeConstants["_EN"]["SEARCH"],
    component: SearchPage,
    exact: true,
  },
  {
    uuid: "searchMapFilters_en",
    path: routeConstants["_EN"]["SEARCH_MAP_FILTERS"],
    component: SearchMapPage,
  },
  {
    uuid: "searchMap_en",
    path: routeConstants["_EN"]["SEARCH_MAP"],
    component: SearchMapPage,
    exact: true,
  },
  {
    uuid: "login_en",
    path: routeConstants["_EN"]["LOGIN"],
    component: LoginPage,
  },
  {
    uuid: "logout_EN",
    path: routeConstants["_EN"]["LOGOUT"],
    component: LogoutPage,
  },
  {
    uuid: "registration_en",
    path: routeConstants["_EN"]["REGISTRATION"],
    component: RegistrationPage,
  },
  {
    uuid: "reservation_en",
    path: routeConstants["_EN"]["RESERVATION"],
    component: ReservationPage,
  },
  {
    uuid: "survey_en",
    path: routeConstants["_EN"]["SURVEY"],
    component: SurveyPage,
  },
  /* COMMON */
  {
    uuid: "not_found",
    component: NotFoundPage,
  },
];

export default routes;
