import React from 'react';
import 'moment/locale/pl.js';
import 'moment/locale/en-gb.js';
import classnames from 'classnames';
import { DatePickerInput } from 'rc-datepicker';
import './Datepicker.scss';

const Datepicker = (props) => {
    const { required } = props;
    const label = props.label ? `${props.label}` : null;
    const hasIcon = props.icon;
    const locale = props.locale ? (props.locale === 'pl' ? 'pl' : 'en-gb') : 'pl';

    return (
        <div className="datepickerContainer">
            <label>
                {hasIcon && (
                    <span className="buttonIcon" onClick={props.icon.onClick || null} onKeyUp={props.icon.onKeyUp || null} onKeyDown={props.icon.onKeyDown || null} data-tip={props.icon.tooltip || ''}>{props.icon.icon}</span>
                )}
                {props.children}
                {label && <p className="datepickerLabel">{label}{required ? <span className="required-sign">*</span> : ''}</p>}
                <DatePickerInput
                  name={props.id}
                  displayFormat='LL'
                  returnFormat='YYYY-MM-DD'
                  //defaultValue={props.value}
                  required={required}
                  placeholder={props.placeholder}
                  onChange={props.onChange || null} 
                  showOnInputClick
                //   {...props}
                  value={props.value}
                  locale={locale}
                  ref={props.ref}
                  className={classnames({
                    "datepicker": true,
                    [`datepicker--${props.type}`]: props.type,
                    [props.className]: props.className,
                  })}
                  autoFocus={props.autoFocus}
                  readOnly={true}
                  closeOnClickOutside={true}
                  minDate={props.minDate || null}
                  maxDate={props.maxDate || null}
                />
            </label>
        </div>
    )
};

export default Datepicker;