import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import SearchPanel from "../../components/searchPanel/SearchPanel";
import Paginator from "../../components/paginator/Paginator";
import Slider from "react-slick";
import Rating from "react-rating";
import { useTranslation, Translation } from "react-i18next";
import { requestService, routeService } from "../../services";
import axios from "axios";
import { NavLink, withRouter } from "react-router-dom";
import { restaurantUtils } from "../../utils";
import RestaurantPromotionInfoModal from "../../components/restaurantPromotionInfoModal/RestaurantPromotionInfoModal";
import { baseConstants } from "../../constants";
import classnames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { configFetched } from "../../actions";
import "./IndexPage.scss";
import { getMainBlogs } from "../../services/strapiService";
import { Logo } from "../../components/image/Image";
import EventModal from "../eventModal/EventModal";
import classNames from "classnames";
import useWindowDimensions from "hooks/useWindowDimensions";
import { FaRegEdit } from "react-icons/fa";
import MapContainer from "../../components/map/Map";
import { addParameterQuery } from "utils/imageUtils";

const mostPopularPersons = 2;

export function loadPromotionData(configData, selectedCity = null) {
  const currentDate = moment().format("YYYY-MM-DD");

  const hasConfigRegions =
    configData &&
    configData.regions &&
    Array.isArray(configData.regions) &&
    configData.regions.length > 0;
  const foundDefaultConfigRegion = hasConfigRegions
    ? configData.regions.find(
        (regionElement) =>
          regionElement.name === baseConstants["BASE_LOCATION_WARSAW"]["name"]
      )
    : null;
  let specificLocation = baseConstants["COOKIE_LOCATION"]?.locationBounds
    ? baseConstants["COOKIE_LOCATION"].locationBounds
    : foundDefaultConfigRegion
    ? foundDefaultConfigRegion.locationBounds
    : baseConstants["BASE_LOCATION_WARSAW"].locationBounds;
  if (selectedCity) {
    const foundNewCity = configData.regions.find(
      (regionElement) =>
        (regionElement.name || "").toLowerCase() ===
        (selectedCity.value || "").toLowerCase()
    );
    if (!foundNewCity) {
      return Promise.reject();
    }
    specificLocation = { ...foundNewCity.locationBounds };
  }

  return requestService.restaurantPromotionsForLocation({
    from: currentDate,
    to: currentDate,
    location: specificLocation,
  });
}

export function loadMostPopularData(
  configData,
  selectedCity = null,
  mostPopularPage = 0,
  mostPopularItemsPerPage = 0
) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm:ss");

  const hasConfigRegions =
    configData &&
    configData.regions &&
    Array.isArray(configData.regions) &&
    configData.regions.length > 0;
  const foundDefaultConfigRegion = hasConfigRegions
    ? configData.regions.find(
        (regionElement) =>
          regionElement.name === baseConstants["BASE_LOCATION_WARSAW"]["name"]
      )
    : null;
  let specificLocation = baseConstants["COOKIE_LOCATION"]?.locationBounds
    ? baseConstants["COOKIE_LOCATION"].locationBounds
    : foundDefaultConfigRegion
    ? foundDefaultConfigRegion.locationBounds
    : baseConstants["BASE_LOCATION_WARSAW"].locationBounds;
  if (selectedCity) {
    const foundNewCity = configData.regions.find(
      (regionElement) =>
        (regionElement.name || "").toLowerCase() ===
        (selectedCity.value || "").toLowerCase()
    );
    if (!foundNewCity) {
      return Promise.reject();
    }
    specificLocation = { ...foundNewCity.locationBounds };
  }

  const mostPopularListRequest = requestService.restaurantList({
    preferences: [],
    favorite: false,
    persons: mostPopularPersons,
    reservationTime: `${currentDate}T${currentTime}`,
    location: specificLocation,
    sort: {
      sortBy: "popularity",
      asc: false,
    },
  });

  return axios
    .all([mostPopularListRequest])
    .then(
      axios.spread((...responses) => {
        const mostPopularOffset = mostPopularPage * mostPopularItemsPerPage;
        const mostPopularSliceFrom = mostPopularOffset;
        const mostPopularLimit =
          mostPopularSliceFrom + mostPopularItemsPerPage <= responses[0].length
            ? mostPopularItemsPerPage
            : responses[0].length >= mostPopularItemsPerPage
            ? responses[0].length - mostPopularItemsPerPage
            : responses[0].length;
        const mostPopularSliceTo = mostPopularSliceFrom + mostPopularLimit;
        const mostPopularSliced = responses[0].slice(
          mostPopularSliceFrom,
          mostPopularSliceTo
        );
        return {
          mostPopular: responses[0],
          mostPopularSliced: mostPopularSliced,
        };
      })
    )
    .catch((errors) => {
      //console.info('@error1', errors);
    });
}

const IndexPage = (props) => {
  const { width } = useWindowDimensions();

  const mostPopularWrapperReference = useRef(null);
  const configData = props.config;
  const [mostPopularIsLoading, setMostPopularIsLoading] = useState(true);
  const [mostPopularIsList, setMostPopularIsList] = useState(true);
  const [mostPopularIsLoadingOpacity, setMostPopularIsLoadingOpacity] =
    useState(false);
  const [promotionIsLoading, setPromotionIsLoading] = useState(true);
  const { t } = useTranslation();
  const [restaurantPromotionModalIsOpen, setRestaurantPromotionModalIsOpen] =
    useState(false);
  const [
    restaurantPromotionModalRecordData,
    setRestaurantPromotionModalRecordData,
  ] = useState(false);

  const [, setBlogs] = useState([]);

  const isMobile = width <= 576;

  const currentLanguage =
    props.location.pathname.indexOf("/en") === 0 ? "en" : "pl";

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 3500,
    cssEase: "ease",
    adaptiveHeight: true,
    centerMode: false,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        {/*<div className="loading" />*/}
      </div>
    ),
  };

  let [restaurants, setRestaurants] = useState([]);
  let [mostPopular, setMostPopular] = useState([]);
  let [mostPopularSliced, setMostPopularSliced] = useState([]);
  const [mostPopularCurrentPage, setMostPopularCurrentPage] = useState(0);
  const [userSelectedLocation, setUserSelectedLocation] = useState(null);
  const mostPopularItemsPerPage = 8;

  useEffect(() => {
    if (!configData) {
      return;
    }

    setPromotionIsLoading(true);
    loadPromotionData(configData, userSelectedLocation).then(
      (data) => {
        setRestaurants(data);
        setPromotionIsLoading(false);
      },
      (errorData) => {
        setPromotionIsLoading(false);
      }
    );
  }, [configData, userSelectedLocation]);

  useEffect(() => {
    async function fetchData() {
      const response = await getMainBlogs();
      setBlogs(response);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!configData) {
      return;
    }
    setMostPopularIsLoadingOpacity(true);
    loadMostPopularData(
      configData,
      userSelectedLocation,
      mostPopularCurrentPage,
      mostPopularItemsPerPage
    ).then(
      (data) => {
        if (typeof data.mostPopular !== "undefined") {
          setMostPopular(data.mostPopular);
        }
        setMostPopularSliced(data.mostPopularSliced);
        setMostPopularIsLoading(false);
        setMostPopularIsLoadingOpacity(false);
      },
      (errorData) => {
        setMostPopularIsLoading(false);
        setMostPopularIsLoadingOpacity(false);
      }
    );
  }, [configData, mostPopularCurrentPage, userSelectedLocation]);

  const restaurantPromotionInfoCloseModal = () => {
    setRestaurantPromotionModalIsOpen(false);
  };

  const restaurantPromotionInfoModalShow = (restaurantData) => {
    setRestaurantPromotionModalRecordData(restaurantData);
    setRestaurantPromotionModalIsOpen(true);
  };

  const renderRestaurants = (data, sliderNameKey = "slider") =>
    (data || []).map((singleRestaurant, singleRestaurantIterator) => {
      if (
        !(
          singleRestaurant &&
          singleRestaurant.restaurantId &&
          singleRestaurant.restaurantId.value
        )
      ) {
        return null;
      }

      return (
        <div
          key={`sliderAlternative--${singleRestaurantIterator}`}
          className="slider__item"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            restaurantPromotionInfoModalShow(singleRestaurant);
          }}
        >
          <div className="slider__item-inside" style={{ cursor: "pointer" }}>
            <div className="slider__itemAnchor">
              <Logo
                id={singleRestaurant.restaurantId}
                url={singleRestaurant.media[0]}
                ext={singleRestaurant.image}
                name={singleRestaurant.restaurantName}
                city={singleRestaurant.address.city}
                className="promotionImage"
              />
            </div>

            <section className="slider__itemContainer">
              <div className="slider__row">
                <div className="slider__row_left">
                  <span className="restaurantName">
                    {singleRestaurant.restaurantName}
                  </span>
                </div>
                <div className="slider__row_right">
                  <span className="locationValuePromo">
                    {singleRestaurant.address.street}
                    {singleRestaurant.address.buildingNumber
                      ? ` ${singleRestaurant.address.buildingNumber}`
                      : ""}
                    {singleRestaurant.address.city
                      ? `, ${singleRestaurant.address.city}`
                      : ""}
                  </span>
                </div>
              </div>
            </section>
            <div className="slider__itemSeparator"></div>
            <div className="slider__itemContainer slider-details-container">
              <span className="categoryName">{singleRestaurant.name}</span>
              <span className="promotionHoursValue">
                {singleRestaurant.effectiveDate !== ""
                  ? singleRestaurant.effectiveDate
                  : "-"}
              </span>
              {!!singleRestaurant.description && (
                <span className="promotionHoursValue promotionRestaurantDescription">
                  <FaRegEdit style={{ marginRight: "6px" }} />
                  {singleRestaurant.description}
                </span>
              )}
            </div>
            <div
              className={classNames(
                "slider__itemContainer",
                "slider__itemContainerNarrow"
              )}
              style={{ display: "flex" }}
            >
              <NavLink
                className="moreInfo"
                to={restaurantUtils.getLink(
                  routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, {
                    selectedPromotion: singleRestaurant.id.value,
                    eventId: singleRestaurant.eventId.value,
                  }),
                  singleRestaurant.restaurantName,
                  singleRestaurant.alias.value
                )}
                onClick={() => {
                  restaurantUtils.pushEventActionGTM("rest_promo_use");
                }}
              >
                {t("indexPage.button.use")}
              </NavLink>
              <span
                className="moreInfo"
                style={{ flex: 1, textAlign: "right", paddingRight: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  restaurantPromotionInfoModalShow(singleRestaurant);
                  restaurantUtils.pushEventActionGTM("rest_promo_info");
                }}
              >
                {t("restaurantPage.button.showMore.promotion")}
              </span>
            </div>
          </div>
        </div>
      );
    });

  const handleBookNow = () => {
    restaurantUtils.pushEventActionGTM("main_page_reserve");
  };

  const mostPopularAlternativeRender = () => {
    return (
      <React.Fragment>
        <Translation>
          {(t) => (
            <h2>
              <span className="mostPopularTitle">
                <strong>{t("indexPage.header.mostPopular")}</strong>{" "}
                {t("indexPage.header.mostPopularExtend")}
              </span>
              {!mostPopularIsLoading && (
                <span className="viewSwitcher">
                  <NavLink
                    className={`viewSwitcher__item viewSwitcher__item--map ${
                      mostPopularIsList && "viewSwitcher__item--hide"
                    }`}
                    activeClassName={
                      !mostPopularIsList ? "viewSwitcher__item--active" : ""
                    }
                    to={""}
                    onClick={(event) => {
                      if (mostPopularIsList) {
                        setMostPopularIsList(false);
                      }
                    }}
                  >
                    {t("searchMapPage.button.map")}
                  </NavLink>
                  <NavLink
                    className={`viewSwitcher__item viewSwitcher__item--list ${
                      !mostPopularIsList && "viewSwitcher__item--hide"
                    }`}
                    activeClassName={
                      mostPopularIsList ? "viewSwitcher__item--active" : ""
                    }
                    to={""}
                    onClick={(event) => {
                      if (!mostPopularIsList) {
                        setMostPopularIsList(true);
                      }
                    }}
                  >
                    {t("searchMapPage.button.list")}
                  </NavLink>
                </span>
              )}
            </h2>
          )}
        </Translation>
        {mostPopularIsLoading && (
          <div className="loaderMask">
            <span className="loaderMaskInside"></span>
          </div>
        )}
        {!mostPopularIsLoading &&
          (mostPopularIsList ? (
            <>
              <div className="restaurantBox">
                {(mostPopularSliced || []).map((singleRestaurant) => (
                  <NavLink
                    className="restaurantBox__item"
                    key={`mostPopular--${singleRestaurant.restaurant.info.id.value}`}
                    to={restaurantUtils.getLink(
                      routeService.getRoute(
                        "RESTAURANT_PROFILE",
                        currentLanguage
                      ),
                      singleRestaurant.restaurant.info.name,
                      singleRestaurant.restaurant.info.alias.value
                    )}
                  >
                    <div className="restaurantBox__item-inside">
                      {configData && configData.ratingOn && (
                        <div className="ratingStars">
                          <Rating
                            readonly={true}
                            placeholderRating={
                              singleRestaurant.restaurant.info.ranking || 0
                            }
                            emptySymbol={
                              <img
                                alt="star"
                                src="/images/icon-star-empty-symbol.svg"
                                className="icon"
                              />
                            }
                            placeholderSymbol={
                              <img
                                alt="star"
                                src="/images/icon-star-full-symbol.svg"
                                className="icon"
                              />
                            }
                            fullSymbol={
                              <img
                                alt="star"
                                src="/images/icon-star-full-symbol.svg"
                                className="icon"
                              />
                            }
                          />
                        </div>
                      )}
                      <Logo
                        id={singleRestaurant.restaurant.info.id}
                        ext={addParameterQuery(
                          singleRestaurant.restaurant.smallImageExtension,
                          "size=SmallImageSize"
                        )}
                        name={singleRestaurant.restaurant.info.name}
                        city={singleRestaurant.restaurant.info.address.city}
                        className="restaurantImage"
                        type="image"
                        imagePrefix="smallimage"
                      />

                      <div
                        className="restaurantBox__item-insideBox"
                        onClick={handleBookNow}
                      >
                        {/* {configData && configData.cuisineOn && (
                        <div className="categoryName">
                          {restaurantUtils.getRestaurantType(
                            singleRestaurant.restaurant.info.preferences
                          )}
                        </div>
                      )} */}
                        <div className="restaurantName">
                          {singleRestaurant.restaurant.info.name}
                        </div>
                        <div className="locationValue">
                          {singleRestaurant.restaurant.info.address.street}
                          {singleRestaurant.restaurant.info.address
                            .buildingNumber
                            ? ` ${singleRestaurant.restaurant.info.address.buildingNumber}`
                            : ""}
                          {singleRestaurant.restaurant.info.address.city
                            ? `, ${singleRestaurant.restaurant.info.address.city}`
                            : ""}
                        </div>
                        <p className="hoursValue">
                          {restaurantUtils
                            .getOpenHours(
                              singleRestaurant.restaurant.info.openHours
                            )
                            .map((dayData, dayIterator) => {
                              const isClosed = !(
                                dayData.openTime && dayData.closeTime
                              );
                              if (!isClosed && !dayData.openTime) {
                                dayData.openTime = "0:00";
                              }
                              if (!isClosed && !dayData.closeTime) {
                                dayData.closeTime = "24:00";
                              }
                              return (
                                <React.Fragment
                                  key={`restaurantProfilePage--openHours--${dayIterator}`}
                                >
                                  {dayIterator > 0 ? <br /> : null}
                                  <span
                                    key={`restaurantProfile--${dayIterator}`}
                                  >
                                    {dayData.day === dayData.dayTo
                                      ? t(
                                          `shortcut.${dayData.day.toLowerCase()}`
                                        )
                                      : `${t(
                                          `shortcut.${dayData.day.toLowerCase()}`
                                        )}—${t(
                                          `shortcut.${dayData.dayTo.toLowerCase()}`
                                        )}`}
                                    :{" "}
                                    {isClosed
                                      ? t(`shortcut.isClosed`)
                                      : `${dayData.openTime}—${dayData.closeTime}`}
                                  </span>
                                </React.Fragment>
                              );
                            })}
                        </p>
                        <div className="preferencesDiv">
                          {singleRestaurant.restaurant.info.preferences && (
                            <div className="preferencesValue">
                              {singleRestaurant.restaurant.info.preferences
                                .map((preference) => preference.description)
                                .join(", ")}
                            </div>
                          )}
                        </div>
                        <p className="aboutRestaurantText">
                          {singleRestaurant.restaurant.info.shortDescription}
                        </p>

                        <span className="bookingAnchor">
                          {t("indexPage.button.bookNow")}
                        </span>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
              {mostPopular && mostPopular.length > 0 && (
                <Paginator
                  uniqueKey="page"
                  dataLength={mostPopular.length}
                  itemsPerPage={mostPopularItemsPerPage}
                  currentPage={mostPopularCurrentPage}
                  onPageClick={onMostPopularPageClick}
                />
              )}
            </>
          ) : (
            <div className="whiteBox">
              <MapContainer
                isMarkerShown
                loadingElement={
                  <div className="loaderMask">
                    <span className="loaderMaskInside"></span>
                  </div>
                }
                containerElement={<div className={"googleMapContainer"} />}
                mapElement={<div style={{ height: `100%` }} />}
                mapOptions={{
                  defaultCenter: { lat: 52.2216345, lng: 21.0087794 },
                  defaultZoom: 12,
                }}
                markers={mostPopular}
                currentLanguage={currentLanguage}
                queryStringParameters={{
                  date: `${moment().format("YYYY-MM-DD")}`,
                  hour: `${moment().format("HH:mm")}`,
                  persons: mostPopularPersons,
                }}
                configuration={props.config}
                history={props.history}
              />
            </div>
          ))}
      </React.Fragment>
    );
  };

  const searchPanelOnChange = (changes) => {
    const { change, value } = changes;
    if (change === "CHANGE_LOCATION_FIELD") {
      const city = value;
      setMostPopularCurrentPage(0);
      setUserSelectedLocation(city);
    }
  };

  const onMostPopularPageClick = (pageData, uniqueKey) => {
    const mostPopularWrapperReferenceYPosition =
      mostPopularWrapperReference.current &&
      mostPopularWrapperReference.current.offsetTop
        ? parseInt(mostPopularWrapperReference.current.offsetTop)
        : undefined;
    if (typeof window !== "undefined") {
      window.scrollTo(0, mostPopularWrapperReferenceYPosition - 90);
    }
    setMostPopularCurrentPage(pageData.value);
  };

  const isRestaurantsAsArray = Array.isArray(restaurants);

  const sliderConfig = {
    slidesCount:
      !isMobile && isRestaurantsAsArray && restaurants.length > 1 ? 2 : 1,
    slidesToScroll: isMobile ? 1 : 2,
    isDesktopWithOneSlide:
      !isMobile && isRestaurantsAsArray && restaurants.length === 1,
  };

  return (
    <div>
      <EventModal />
      <Helmet>
        <title>{t("seo.subpage.main.title")}</title>
        <meta name="description" content={t("seo.subpage.main.description")} />
      </Helmet>
      <div className="insideContainer indexPageContainer">
        <RestaurantPromotionInfoModal
          isOpen={restaurantPromotionModalIsOpen}
          onRequestClose={restaurantPromotionInfoCloseModal}
          data={restaurantPromotionModalRecordData}
          currentLanguage={currentLanguage}
          configuration={configData}
          page="main-page"
        />
        <SearchPanel
          props={props}
          configuration={configData}
          onChange={searchPanelOnChange}
        >
          <Translation>
            {(t) => (
              <h1>
                {t("indexPage.background.header")}
                <br /> <strong>{t("indexPage.background.headerExtend")}</strong>
              </h1>
            )}
          </Translation>
        </SearchPanel>

        <div className="standardPageContainer__inside indexPageContainer__inside">
          {!promotionIsLoading && restaurants && restaurants.length > 0 && (
            <div
              className={classnames({
                sliderContainer: true,
                "sliderContainer--show":
                  Array.isArray(restaurants) && restaurants.length > 0,
              })}
            >
              <Translation>
                {(t) => (
                  <h2>
                    <strong>{t("indexPage.header.currentPromotions")}</strong>{" "}
                    {t("indexPage.header.currentPromotionsExtend")}
                  </h2>
                )}
              </Translation>
              {Array.isArray(restaurants) && (
                <Slider
                  {...sliderSettings}
                  slidesToShow={sliderConfig.slidesCount}
                  slidesToScroll={sliderConfig.slidesToScroll}
                  className={classnames(
                    {
                      slider: true,
                    },
                    sliderConfig.isDesktopWithOneSlide
                      ? "desktop-one-slide"
                      : ""
                  )}
                >
                  {renderRestaurants(restaurants, "sliderKey")}
                </Slider>
              )}
            </div>
          )}
          <div
            className={classnames({
              mostPopularWrapper: true,
              "mostPopularWrapper--isLoading": mostPopularIsLoadingOpacity,
            })}
            ref={mostPopularWrapperReference}
          >
            {mostPopularAlternativeRender()}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = { configFetched };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexPage)
);
