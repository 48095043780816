import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Header from "../components/header/Header";
import ScrollButton from "./ScrollButton";
import Footer from "../components/footer/Footer";
import CookiesPolicyLine from "../components/cookiesPolicyLine/CookiesPolicyLine";
import { ToastProvider } from "react-toast-notifications";
import "../i18n";
import "../config/axios.config";
import { default as routes } from "../routes";
import { requestService, routeService } from "../services";
import { requestConstants } from "../constants";
import { connect } from "react-redux";
import { configFetched } from "../actions";
import i18n from "i18next";
import { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
import queryString from "query-string";

const Layout = (props) => {
  const { history, location } = props;
  const propsConfigFetched = props.configFetched;
  const currentLanguage = location.pathname.indexOf("/en") === 0 ? "en" : "pl";
  const [forceRender, setForceRender] = useState(null);
  const hasLocalStorage = typeof window.localStorage !== "undefined";
  const [cookies] = useCookies(["MS_WEB_RESTAURANT_CLIENT_PORTAL_LANG"]);
  const { search } = useLocation();
  const values = queryString.parse(search);

  const clearLocalStorageItems = () => {
    localStorage.removeItem("loggedUserData");
    localStorage.removeItem("facebookData");
    localStorage.removeItem("googleData");
    setForceRender(+new Date());
  };

  const axiosErrorHandler = (errorData) => {
    if (
      errorData &&
      errorData.response &&
      errorData.response.status &&
      errorData.config &&
      errorData.config.url
    ) {
      const isLoginRequest =
        errorData.config.url.indexOf(requestConstants["LOGIN"]["URL"]) > -1;
      const isGetUserDataRequest =
        errorData.config.url.indexOf(requestConstants["GET_USER_DATA"]["URL"]) >
        -1;

      const arrayOfPluginUrls = [
        requestConstants["LOGIN_FACEBOOK"]["URL"],
        requestConstants["APPLE_FACEBOOK"]["URL"],
        requestConstants["LOGIN_GOOGLE"]["URL"],
      ];

      const isPluginErrorLogin =
        errorData.config.url.indexOf(arrayOfPluginUrls[0]) > -1 ||
        errorData.config.url.indexOf(arrayOfPluginUrls[1]) > -1 ||
        errorData.config.url.indexOf(arrayOfPluginUrls[2]) > -1;

      switch (errorData.response.status) {
        //case 403:
        case 401:
          // const loggedUserDataLS = JSON.parse(localStorage.getItem('loggedUserData') || '{}');
          // const isLoggedUserLS = Object.keys(loggedUserDataLS).length > 0;
          clearLocalStorageItems();

          if (errorData.config.url.indexOf("auth") === -1) {
            // if (isGetUserDataRequest && isLoggedUserLS) {
            //   clearLocalStorageItems();
            // }
            if (!isPluginErrorLogin) {
              if (isLoginRequest || isGetUserDataRequest) {
                return Promise.reject(errorData);
              }
              props.history.push(
                routeService.getRoute("LOGIN", currentLanguage)
              );
            }
          }
          break;

        default:
          break;
      }
    }

    const rejectData =
      errorData && errorData.response && errorData.response.data
        ? errorData.response.data
        : errorData;
    return Promise.reject(rejectData);
  };

  axios.interceptors.response.use((response) => {
    return response;
  }, axiosErrorHandler);

  useEffect(() => {
    const configurationRequest = requestService.configuration();
    const languageData = requestService.languageData(currentLanguage);
    languageData.then((translations) =>
      i18n.addResources(currentLanguage, "translation", translations)
    );
    configurationRequest.then(
      (configData) => {
        propsConfigFetched(configData);
      },
      (configError) => {
        //
      }
    );

    return () => {
      //
    };
  }, [propsConfigFetched, currentLanguage]);

  useEffect(() => {
    if (!hasLocalStorage) {
      return;
    }
    const userDataRequest = requestService.getUserData();
    userDataRequest.then(
      (userData) => {
        localStorage.setItem("loggedUserData", JSON.stringify(userData));
        setForceRender(+new Date());
      },
      (configError) => {
        //
      }
    );
  }, [hasLocalStorage]);

  useEffect(() => {
    let previousPathnameScroll = location.pathname;
    const historyListener = history.listen((location, action) => {
      if (
        typeof window === "object" &&
        previousPathnameScroll !== location.pathname
      ) {
        window.scrollTo(0, 0);
      }
      previousPathnameScroll = location.pathname;
    });

    return () => {
      historyListener();
    };
  }, [history, location]);

  useEffect(() => {
    if (location.pathname === "/") {
      const language = cookies["MS_WEB_RESTAURANT_CLIENT_PORTAL_LANG"] ?? "";
      language && i18n.changeLanguage(language);
      language && props.history.replace(`/${language}`);
    }
  }, []);

  return (
    <>
      <Header forceRender={forceRender} />
      <div
        className={
          values.plugin
            ? "mainContainer mainContainer--plugin"
            : "mainContainer"
        }
      >
        <React.Suspense
          fallback={
            <div className="loaderMask">
              <span className="loaderMaskInside"></span>
            </div>
          }
        >
          <ToastProvider placement={"top-right"}>
            <Toaster position="top-center" />
            <Switch>
              <Route
                exact
                path="/covid"
                render={() => {
                  return <Redirect to="/" />;
                }}
              />
              {routes.map((routeItem) => (
                <Route
                  key={routeItem.uuid || "no-value"}
                  path={routeItem.path || ""}
                  exact={routeItem.exact || false}
                  component={routeItem.component || false}
                ></Route>
              ))}
            </Switch>
          </ToastProvider>
        </React.Suspense>
      </div>
      {!values.plugin && <ScrollButton />}
      <Footer forceRender={forceRender} />
      {typeof window.localStorage !== "undefined" && <CookiesPolicyLine />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = { configFetched };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
