import axios from "axios";
import { requestConstants } from "../constants";
import i18n from "i18next";

function callAxios(method, url, payload, headers, response_type) {
  const lang = i18n.language ?? "pl";
  return axios({
    method: method,
    url: url,
    data: payload,
    headers: Object.assign({}, headers, { "Device-Locale": lang }),
    reponseType: response_type ?? undefined,
  }).then((data) => {
    if (data && data.data) {
      return data.data;
    }

    return data;
  });
}

//Axios function the same but blob works
function callAxiosBlob(method, url, payload, headers, response_type) {
  const lang = i18n.language ?? "pl";
  return axios({
    method: method,
    url: url,
    data: payload,
    headers: Object.assign({}, headers, { "Device-Locale": lang }),
    responseType: response_type ?? undefined,
  }).then((data) => {
    if (data && data.data) {
      return data.data;
    }
    return data;
  });
}

/**
 * Language data request.
 * @param {String} [language='pl']
 * @returns <Promise>
 */
function languageData(language = "pl") {
  const requestConstantName = "LANGUAGE_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{language}",
      language
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  const payload = {};

  return callAxios(method, url, payload, headers);
}

/**
 * Remove account by google request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function removeAccountByGoogle(payload = {}) {
  const requestConstantName = "REMOVE_ACCOUNT_BY_GOOGLE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Remove account by facebook request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function removeAccountByFacebook(payload = {}) {
  const requestConstantName = "REMOVE_ACCOUNT_BY_FACEBOOK";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Remove account request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function removeAccount(payload = {}) {
  const requestConstantName = "REMOVE_ACCOUNT";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Login request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function login(payload = {}) {
  const requestConstantName = "LOGIN";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Login via Facebook request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function loginFacebook(payload = {}) {
  const requestConstantName = "LOGIN_FACEBOOK";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Login via Facebook request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function loginApple(payload = {}) {
  const requestConstantName = "APPLE_FACEBOOK";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Login via Google request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function loginGoogle(payload = {}) {
  const requestConstantName = "LOGIN_GOOGLE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Logout request.
 * @returns <Promise>
 */
function logout() {
  const requestConstantName = "LOGOUT";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};

  return callAxios(method, url, payload, headers);
}

/**
 * Registration request.
 * @param {String} [payload={}]
 * @returns <Promise>
 */
function registration(payload = {}) {
  const requestConstantName = "REGISTRATION";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Registration via Facebook request.
 * @param {String} [payload={}]
 * @returns <Promise>
 */
function registrationFacebook(payload = {}) {
  const requestConstantName = "REGISTRATION_FACEBOOK";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Registration via Google request.
 * @param {String} [payload={}]
 * @returns <Promise>
 */
function registrationGoogle(payload = {}) {
  const requestConstantName = "REGISTRATION_GOOGLE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Registration activation request.
 * @param {String} [payload={}]
 * @returns <Promise>
 */
function registrationActivation(payload = {}) {
  const requestConstantName = "REGISTRATION_ACTIVATION";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Registration send code again request.
 * @param {String} [payload={}]
 * @returns <Promise>
 */
function registrationSendCodeAgain(payload = {}) {
  const requestConstantName = "REGISTRATION_SEND_CODE_AGAIN";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Restore password request.
 * @param {Object} [payload={}]
 * @returns <Promise>
 */
function restorePassword(payload = {}) {
  const requestConstantName = "RESTORE_PASSWORD";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

/**
 * Configuration request.
 * @returns <Promise>
 */
function configuration() {
  const requestConstantName = "CONFIGURATION";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};

  return callAxios(method, url, payload, headers);
}

function getUserData(payload = {}) {
  const requestConstantName = "GET_USER_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function updateUserData(payload = {}) {
  const requestConstantName = "UPDATE_USER_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function getActiveEvent(payload = {}) {
  const method = requestConstants.GET_ACTIVE_EVENT.METHOD;
  const url = `${requestConstants.API_BASE_URL}${requestConstants.GET_ACTIVE_EVENT.URL}`;
  let headers = {
    "Content-Type": requestConstants.GET_ACTIVE_EVENT.CONTENT_TYPE,
  };

  return callAxios(method, url, payload, headers);
}

function changeUserPassword(payload = {}) {
  const requestConstantName = "CHANGE_PASSWORD";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function reservationTemporaryBooking(payload = {}) {
  const requestConstantName = "RESERVATION_TEMPORARY_BOOKING";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function reservationPreConfirmBooking(payload = {}) {
  const requestConstantName = "RESERVATION_PRE_CONFIRM_BOOKING";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function calculatePayment(payload = {}) {
  const requestConstantName = "CALCULATE_PAYMENT";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function reservationConfirmBooking(payload = {}) {
  const requestConstantName = "RESERVATION_CONFIRM_BOOKING";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function reservationHistory(payload = {}) {
  const requestConstantName = "RESERVATION_HISTORY";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function cancelReservationByToken(payload) {
  const requestConstantName = "RESERVATION_BY_TOKEN_CANCEL";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function reservationByToken(token) {
  const requestConstantName = "RESERVATION_BY_TOKEN";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}/${token}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, {}, headers);
}

function reservationCurrent(payload = {}) {
  const requestConstantName = "RESERVATION_CURRENT";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function removeReservation(payload = {}) {
  const requestConstantName = "RESERVATION_REMOVE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function cancelReservation(payload = {}) {
  const requestConstantName = "RESERVATION_CANCEL";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function restaurantList(payload = {}) {
  const requestConstantName = "RESTAURANT_LIST";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function restaurantDetails(uuid) {
  const requestConstantName = "RESTAURANT_DETAILS";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{uuid}",
      uuid
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};

  return callAxios(method, url, payload, headers);
}

// TODO add eventid
function searchSingleRestaurant(
  restaurantUUID,
  reservationTime,
  persons = 2,
  eventId = null,
  productId = null
) {
  const requestConstantName = "RESTAURANT_SINGLE_RESTAURANT";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {
    restaurantId: restaurantUUID,
    reservationTime: reservationTime,
    persons: parseInt(persons),
    eventId: eventId ? parseInt(eventId) : null,
    products: productId ? [parseInt(productId)] : null,
  };

  return callAxios(method, url, payload, headers);
}

function restaurantGallery(restaurantUUID, from, to) {
  const requestConstantName = "RESTAURANT_GALLERY";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`
      .replace("{restaurantUUID}", restaurantUUID)
      .replace("{from}", from)
      .replace("{to}", to);
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};

  return callAxios(method, url, payload, headers);
}

function restaurantPromotionsForLocation(payload = {}) {
  const requestConstantName = "PROMOTIONS_FOR_LOCATION";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function restaurantPromotions(restaurantName) {
  const requestConstantName = "RESTAURANT_PROMOTIONS";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{restaurantName}",
      restaurantName
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};

  return callAxios(method, url, payload, headers);
}

function reservationPaymentUrl(payload = {}) {
  const requestConstantName = "RESERVATION_PAYMENT_URL";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  return callAxios(method, url, payload, headers);
}

function getOnboardingConfig() {
  const requestConstantName = "GET_ONBOARDING_CONFIG";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_ONBOARDING_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};
  return callAxios(method, url, payload, headers);
}

function getOnboardingDiscountConfig(discountCode) {
  const requestConstantName = "GET_ONBOARDING_DISCOUNT_CONFIG";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_ONBOARDING_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{discountCode}",
      discountCode
    );

  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};
  return callAxios(method, url, payload, headers);
}

function getNipData(nipNumber) {
  const requestConstantName = "GET_NIP_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_ONBOARDING_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{nipNumber}",
      nipNumber
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};
  return callAxios(method, url, payload, headers);
}

function getContractPdf(payload = {}) {
  const requestConstantName = "GET_CONTRACT_PDF";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_ONBOARDING_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const response_type = requestConstants[requestConstantName]["RESPONSE_TYPE"];
  return callAxiosBlob(method, url, payload, headers, response_type);
}

function postOnboardingFormData(payload = {}) {
  const requestConstantName = "POST_ONBOARDING_FORM_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_ONBOARDING_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  return callAxios(method, url, payload, headers);
}

function sendMessage(payload) {
  const requestConstantName = "POST_CHAT_ADD";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function sendRestaurantMessage(payload) {
  const requestConstantName = "POST_CHAT_ADD_MESSAGE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function getChat(reservationId, token) {
  let requestConstantName = "GET_CHAT_DATA";
  let method = requestConstants[requestConstantName]["METHOD"];
  let url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{reservationId}",
      reservationId
    );

  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  if (token) {
    requestConstantName = "GET_RESTAURANT_CHAT_DATA";
    method = requestConstants[requestConstantName]["METHOD"];
    url =
      `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
        "{token}",
        token
      );
    headers = {
      "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
    };
  }

  const payload = {};
  return callAxios(method, url, payload, headers);
}

function getCompanyData(nipNumber) {
  const requestConstantName = "GET_COMPANY_DATA";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{nipNumber}",
      nipNumber
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };
  const payload = {};
  return callAxios(method, url, payload, headers);
}

function getSurveyTemplate(restaurantId) {
  const requestConstantName = "GET_SURVEY_TEMPLATE";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url =
    `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`.replace(
      "{restaurantId}",
      restaurantId
    );
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, headers);
}

function postSurveyResults(payload) {
  const requestConstantName = "POST_SURVEY_RESULTS";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

function checkSurveyStatus(payload) {
  const requestConstantName = "CHECK_FILLED_SURVEY";
  const method = requestConstants[requestConstantName]["METHOD"];
  const url = `${requestConstants["API_BASE_URL"]}${requestConstants[requestConstantName]["URL"]}`;
  let headers = {
    "Content-Type": requestConstants[requestConstantName]["CONTENT_TYPE"],
  };

  return callAxios(method, url, payload, headers);
}

const requestService = {
  languageData,
  removeAccountByGoogle,
  removeAccountByFacebook,
  removeAccount,
  login,
  loginFacebook,
  loginApple,
  loginGoogle,
  logout,
  registration,
  registrationFacebook,
  registrationGoogle,
  registrationActivation,
  registrationSendCodeAgain,
  restorePassword,
  configuration,
  getUserData,
  updateUserData,
  changeUserPassword,
  reservationTemporaryBooking,
  reservationPreConfirmBooking,
  reservationConfirmBooking,
  reservationHistory,
  reservationByToken,
  reservationCurrent,
  removeReservation,
  cancelReservation,
  cancelReservationByToken,
  reservationPaymentUrl,
  restaurantList,
  restaurantDetails,
  searchSingleRestaurant,
  restaurantGallery,
  restaurantPromotionsForLocation,
  restaurantPromotions,
  getActiveEvent,
  calculatePayment,
  getOnboardingConfig,
  getOnboardingDiscountConfig,
  getNipData,
  getContractPdf,
  postOnboardingFormData,
  sendMessage,
  sendRestaurantMessage,
  getChat,
  getCompanyData,
  getSurveyTemplate,
  postSurveyResults,
  checkSurveyStatus,
};

export default requestService;
