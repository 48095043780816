import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import DropDownListSuggest from "../dropDownListSuggest/DropDownListSuggest";
import Datepicker from "../datepicker/Datepicker";
import { routeService } from "../../services";
import { baseConstants } from "../../constants";
import classnames from "classnames";
import { restaurantUtils } from "../../utils";
import { FaStar, FaDollarSign } from "react-icons/fa";
import Ink from "react-ink";
import useAsyncEffect from "use-async-effect";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import "./SearchPanel.scss";
import { useCookies } from "react-cookie";

const SearchForm = ({
  props,
  additionalFilters,
  showAdditionalFilters,
  hideAdditionalFilters,
}) => {
  const configurationData = props.configuration || null;
  const URLQueryString = props.props.location.search;
  const currentLanguage =
    props.props.location.pathname.indexOf("/en") === 0 ? "en" : "pl";

  const renderIcon = (
    count,
    IconName,
    classNames = "optionIcon",
    prefixKey = ""
  ) => {
    let icons = [];
    for (let i = 0; i < count; i++) {
      icons.push(<IconName className={classNames} />);
    }
    return icons.map((Icon, IconIterator) => (
      <React.Fragment key={`${prefixKey}${IconIterator}`}>
        {Icon}
      </React.Fragment>
    ));
  };

  const setIconsAsState = (count, IconName, classNames = "optionIcon") => {
    let data = [];
    for (let i = 1; i <= count; i++) {
      data.push({
        value: String(i),
        label: (
          <React.Fragment>{renderIcon(i, IconName, classNames)}</React.Fragment>
        ),
      });
    }
    return data;
  };

  const [initialFormData, setInitialFormData] = useState(null);
  const [hourList, setHourList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [restaurantTypeList, setRestaurantTypeList] = useState([]);
  const [ratingList] = useState(setIconsAsState(5, FaStar, "optionIcon"));
  const [priceLevelList] = useState(
    setIconsAsState(
      5,
      FaDollarSign,
      "optionIcon optionIcon--widthTenPx optionIcon--marginTopOnePx"
    )
  );
  const firstAvailableTime = restaurantUtils.getFirstAvailableTime();
  const currentDate = firstAvailableTime["date"];
  const currentHour = firstAvailableTime["hour"];
  const currentMinute = firstAvailableTime["minute"];
  const { t } = useTranslation();
  const urlParams = queryString.parse(props.props.location.search); //props.props.match.params;
  const [cookies, setCookie] = useCookies(["localization"]);

  baseConstants["COOKIE_LOCATION"] = configurationData?.regions?.find(
    (i) =>
      i.name ===
      (cookies?.Location
        ? cookies.Location.name
        : baseConstants["BASE_LOCATION_WARSAW"].name)
  );

  useEffect(() => {
    if (!configurationData) {
      return;
    }
    const newRestaurantTypeList = (configurationData.cuisineTypes || [])
      .map((cuisineType) => {
        return {
          label: cuisineType.description,
          value: cuisineType.key,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    const newLocationList = (configurationData.regions || [])
      .map((region) => {
        return {
          label: region.name,
          value: region.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.label === value.label && t.value === value.value
          )
      );
    let newPersonListData = [];
    for (
      let personIndex = 1;
      personIndex <=
      (configurationData && configurationData.maxPersons
        ? configurationData.maxPersons
        : 10);
      personIndex++
    ) {
      newPersonListData.push({
        label: `${personIndex} ${t(
          "indexPage.input.label.persons.optionExtend"
        )}`,
        value: String(personIndex),
      });
    }
    setRestaurantTypeList(newRestaurantTypeList);
    setLocationList(newLocationList);
    setPersonList(newPersonListData);
  }, [configurationData, t]);

  /**
   * It's native react function.
   */
  useAsyncEffect(() => {
    const defaultDate =
      urlParams && urlParams.date ? urlParams.date : currentDate;

    const getSpecificRating = (ratingValue) => {
      return renderIcon(parseInt(ratingValue), FaStar, "optionIcon", "rating");
    };

    const getSpecificPriceLevel = (priceLevelValue) => {
      return renderIcon(
        parseInt(priceLevelValue),
        FaDollarSign,
        "optionIcon optionIcon--widthTenPx optionIcon--marginTopOnePx",
        "priceLevel"
      );
    };

    const newInitialFormData = Object.assign({}, initialFormData, {
      date: defaultDate,
      hour:
        urlParams && urlParams.hour
          ? {
              label:
                currentLanguage === "pl"
                  ? `${urlParams.hour}`
                  : moment(`${defaultDate} ${urlParams.hour}:00`)
                      .locale("en")
                      .format("LT"),
              value: `${urlParams.hour}`,
            }
          : {
              label:
                currentLanguage === "pl"
                  ? `${currentHour}:${currentMinute}`
                  : moment(`${defaultDate} ${currentHour}:${currentMinute}:00`)
                      .locale("en")
                      .format("LT"),
              value: `${currentHour}:${currentMinute}`,
            },
      location: {
        label:
          urlParams && urlParams.location
            ? `${urlParams.location}`
            : cookies?.Location?.name ??
              baseConstants["BASE_LOCATION_WARSAW"].name,
        value:
          urlParams && urlParams.location
            ? `${urlParams.location}`
            : cookies?.Location?.name ??
              baseConstants["BASE_LOCATION_WARSAW"].name,
      },
      searchByName: urlParams && urlParams.name ? urlParams.name : "",
      persons: {
        label:
          urlParams && urlParams.persons
            ? `${urlParams.persons} ${t(
                "indexPage.input.label.persons.optionExtend"
              )}`
            : `2 ${t("indexPage.input.label.persons.optionExtend")}`,
        value: urlParams && urlParams.persons ? `${urlParams.persons}` : "2",
      },
      priceLevel:
        urlParams && urlParams.priceLevel
          ? {
              label: getSpecificPriceLevel(String(urlParams.priceLevel)),
              value: urlParams.priceLevel,
            }
          : null,
      rating:
        urlParams && urlParams.rating
          ? {
              label: getSpecificRating(String(urlParams.rating)),
              value: `${urlParams.rating}`,
            }
          : null,
      restaurantType:
        urlParams && urlParams.restaurantType
          ? {
              label: `${
                restaurantTypeList && restaurantTypeList.length > 0
                  ? restaurantTypeList.find(
                      (r) => r.value === urlParams.restaurantType
                    )?.label ?? ""
                  : ""
              }`,
              value: `${urlParams.restaurantType}`,
            }
          : null,
    });
    setInitialFormData(newInitialFormData);
  }, [URLQueryString, currentLanguage, restaurantTypeList]);

  useAsyncEffect(() => {
    const hourListData = restaurantUtils
      .getFullAvailableHours(currentLanguage)
      .map((hr) => ({ label: hr.label, value: hr.hour }));
    setHourList(hourListData);
  }, []);

  const onSubmitHandler = (values, { setSubmitting, resetForm }) => {
    restaurantUtils.pushEventActionGTM("main_page_search");
    const currentPath = props.props.match.path;
    const searchMapRouteURL = routeService.getRoute(
      "SEARCH_MAP",
      currentLanguage
    );
    const currentRouteName =
      currentPath.indexOf(searchMapRouteURL) === 0 ? "SEARCH_MAP" : "SEARCH";

    if (typeof props.onChange === "function") {
      props.onChange({
        change: "ON_SEARCH",
        value: true,
      });
    }

    let queryStringRoute = {
      location:
        values.location && values.location.value
          ? values.location.value
          : cookies?.Location?.name ??
            baseConstants["BASE_LOCATION_WARSAW"].name,
      persons:
        values.persons && values.persons.value ? values.persons.value : "2",
    };

    if (values.date) {
      queryStringRoute["date"] = values.date;
    }
    if (values.hour && values.hour.value) {
      queryStringRoute["hour"] = values.hour.value;
    }
    if (values.searchByName) {
      queryStringRoute["name"] = values.searchByName;
    }
    if (values.restaurantType && values.restaurantType.value) {
      queryStringRoute["restaurantType"] = values.restaurantType.value;
    }
    if (values.rating && values.rating.value) {
      queryStringRoute["rating"] = values.rating.value;
    }
    if (values.priceLevel && values.priceLevel.value) {
      queryStringRoute["priceLevel"] = values.priceLevel.value;
    }

    queryStringRoute["page"] = 0;

    props.props.history.push(
      `${routeService.getRoute(
        currentRouteName,
        currentLanguage,
        queryStringRoute
      )}`
    );
  };

  const cuisineOn = configurationData && configurationData.cuisineOn;
  const ratingOn = configurationData && configurationData.ratingOn;
  const priceOn = configurationData && configurationData.priceOn;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormData}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={onSubmitHandler}
    >
      {({ values, setFieldValue, submitForm }) => {
        const setField = (fieldName, fieldValue) => {
          const newInitialFormData = Object.assign({}, values, {
            [fieldName]: fieldValue,
          });
          setFieldValue(fieldName, fieldValue);
          setInitialFormData(newInitialFormData);
          if (typeof props.onChange === "function") {
            props.onChange({
              change: "INIT_VALUES_CHANGED",
              value: newInitialFormData,
            });
          }
        };

        return (
          <Form id="search-form">
            <div className="filtersContainer">
              <div className="filtersContainer__firstColumn">
                <div className="fieldElements">
                  <div className="fieldElement fieldElement--location">
                    <DropDownListSuggest
                      value={values && values.location}
                      required={false}
                      options={locationList || []}
                      id="location"
                      name="location"
                      label={t("indexPage.input.label.location")}
                      placeholder={t("indexPage.input.placeholder.select")}
                      onChange={(selectedOption) => {
                        if (typeof props.onChange === "function") {
                          props.onChange({
                            change: "CHANGE_LOCATION_FIELD",
                            value: selectedOption,
                          });
                        }
                        setField(`location`, selectedOption);
                        const region = configurationData?.regions?.find(
                          (i) => i.name === selectedOption.value
                        );

                        if (region) {
                          setCookie("Location", region, { path: "/" });
                          baseConstants["COOKIE_LOCATION"] = region;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="filtersContainer__secondColumn">
                <div className="fieldElements">
                  <div className="fieldElement fieldElement--date">
                    <Datepicker
                      locale={currentLanguage}
                      required={false}
                      id="date"
                      name="date"
                      label={t("indexPage.input.label.date")}
                      placeholder={t("indexPage.input.placeholder.select")}
                      onChange={(jsDate, dateString) => {
                        const selectedTimestamp = moment(
                          `${dateString} ${
                            values.hour.value ? values.hour.value : "23:59"
                          }:59`
                        ).unix();
                        const currentTimestamp = moment().unix();
                        const inFuture = currentTimestamp <= selectedTimestamp;
                        if (inFuture) {
                          setField(`date`, dateString);
                        } else {
                          const defaultValue = moment().format("YYYY-MM-DD");
                          setField(`date`, defaultValue);
                        }
                      }}
                      value={values && values.date}
                      minDate={moment()}
                      maxDate={
                        configurationData &&
                        configurationData.inFutureReservationDuration
                          ? restaurantUtils.getMaxDateInFuture(
                              configurationData.inFutureReservationDuration
                            )
                          : null
                      }
                    />
                  </div>

                  <div className="fieldElement fieldElement--hour">
                    <DropDownListSuggest
                      value={values && values.hour}
                      required={false}
                      options={hourList || []}
                      id="hour"
                      name="hour"
                      label={t("indexPage.input.label.hour")}
                      placeholder={t("indexPage.input.placeholder.select")}
                      onChange={(selectedOption) => {
                        setField(`hour`, selectedOption);
                      }}
                    />
                  </div>

                  <div className="fieldElement fieldElement--persons">
                    <DropDownListSuggest
                      value={values && values.persons}
                      required={false}
                      options={personList || []}
                      id="persons"
                      name="persons"
                      label={t("indexPage.input.label.persons")}
                      placeholder={t("indexPage.input.placeholder.select")}
                      onChange={(selectedOption) => {
                        setField(`persons`, selectedOption);
                      }}
                    />
                  </div>

                  <div className="fieldElement fieldElement--submit">
                    <NavLink
                      to={routeService.getRoute("SEARCH", currentLanguage, {
                        location: "Szczecin",
                      })}
                      className="forReactSnap"
                    >
                      Szczecin
                    </NavLink>
                    <NavLink
                      to={routeService.getRoute("SEARCH", currentLanguage, {
                        location: "Warszawa",
                      })}
                      className="forReactSnap"
                    >
                      Warszawa
                    </NavLink>
                    <NavLink
                      to={routeService.getRoute("SEARCH", currentLanguage, {
                        location: "Radom",
                      })}
                      className="forReactSnap"
                    >
                      Radom
                    </NavLink>
                    <button
                      className="MSButton MSButton--orange MSButton--pointer MSButton--onlyIcon hideOnPC"
                      type="submit"
                    >
                      <span className="searchIconInlineWithoutText"></span>
                      <Ink />
                    </button>
                    <button
                      className="MSButton MSButton--orange MSButton--pointer hideOnTablet"
                      type="submit"
                    >
                      <span className="searchIconInline">
                        {t("indexPage.button.search")}
                      </span>
                      <Ink />
                    </button>
                  </div>
                </div>
              </div>

              <div className="filtersContainer__row filtersContainer__row--additional">
                <div className="fieldElements">
                  {cuisineOn && (
                    <div
                      className={classnames({
                        fieldElement: true,
                        "fieldElement--restaurantType": true,
                        "fieldElement--hidden": !cuisineOn,
                      })}
                    >
                      <DropDownListSuggest
                        value={values && values.restaurantType}
                        required={false}
                        options={restaurantTypeList || []}
                        id="restaurantType"
                        name="restaurantType"
                        label={t("indexPage.input.label.restaurantType")}
                        placeholder={t("indexPage.input.placeholder.select")}
                        onChange={(selectedOption) => {
                          setField(`restaurantType`, selectedOption);
                        }}
                        isClearable={true}
                        isSearchable={true}
                      />
                    </div>
                  )}
                  {ratingOn && (
                    <div
                      className={classnames({
                        fieldElement: true,
                        "fieldElement--rating": true,
                        "fieldElement--hidden": !ratingOn,
                      })}
                    >
                      <DropDownListSuggest
                        value={values && values.rating}
                        required={false}
                        options={ratingList || []}
                        id="rating"
                        name="rating"
                        label={t("indexPage.input.label.rating")}
                        placeholder={t("indexPage.input.placeholder.select")}
                        onChange={(selectedOption) => {
                          setField(`rating`, selectedOption);
                        }}
                        isClearable={true}
                      />
                    </div>
                  )}
                  {priceOn && (
                    <div
                      className={classnames({
                        fieldElement: true,
                        "fieldElement--priceLevel": true,
                        "fieldElement--hidden": !priceOn,
                      })}
                    >
                      <DropDownListSuggest
                        value={values && values.priceLevel}
                        required={false}
                        options={priceLevelList || []}
                        id="priceLevel"
                        name="priceLevel"
                        label={t("indexPage.input.label.priceLevel")}
                        placeholder={t("indexPage.input.placeholder.select")}
                        onChange={(selectedOption) => {
                          setField(`priceLevel`, selectedOption);
                        }}
                        isClearable={true}
                      />
                    </div>
                  )}
                  <div className="fieldElement fieldElement--searchByName">
                    <div className="inputWrapper">
                      <div className="searchByNameInput">
                        <label style={{ width: "100%" }}>
                          <span className="akaLabel">
                            {t("indexPage.input.label.searchByName")}
                          </span>
                          <Field
                            className="input"
                            type="text"
                            id="searchByName"
                            name="searchByName"
                            placeholder={t("indexPage.input.placeholder.type")}
                            value={
                              values && values.searchByName
                                ? values.searchByName
                                : ""
                            }
                            onChange={(newValue) => {
                              setFieldValue(
                                `searchByName`,
                                newValue.currentTarget.value
                              );
                            }}
                          />
                        </label>
                        {values && values.searchByName !== "" && (
                          <button
                            type="button"
                            className="clearInput"
                            onClick={() => {
                              setFieldValue("searchByName", "");
                            }}
                          >
                            x
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filtersContainer__row--mobileSubmit">
                <div className="fieldElement fieldElement--submit">
                  <NavLink
                    to={routeService.getRoute("SEARCH", currentLanguage, {
                      location: "Szczecin",
                    })}
                    className="forReactSnap"
                  >
                    Szczecin
                  </NavLink>
                  <NavLink
                    to={routeService.getRoute("SEARCH", currentLanguage, {
                      location: "Warszawa",
                    })}
                    className="forReactSnap"
                  >
                    Warszawa
                  </NavLink>
                  <NavLink
                    to={routeService.getRoute("SEARCH", currentLanguage, {
                      location: "Radom",
                    })}
                    className="forReactSnap"
                  >
                    Radom
                  </NavLink>
                  <button
                    className="MSButton MSButton--orange MSButton--pointer"
                    type="submit"
                  >
                    <span className="searchIconInline">
                      {t("indexPage.button.search")}
                    </span>
                    <Ink />
                  </button>
                </div>
              </div>
            </div>

            <div
              className={classnames({
                additionalFiltersContainer: true,
                "additionalFiltersContainer--moreTopMargin": additionalFilters,
              })}
            >
              {/* {additionalFilters && (
              <>
                <span
                  className="additionalFilterAnchor"
                  onClick={hideAdditionalFilters || null}
                >
                  {t("indexPage.button.hideAdditionalFilters")}
                </span>
              </>
            )}
            {!additionalFilters && (
              <>
                <span
                  className="additionalFilterAnchor"
                  onClick={showAdditionalFilters || null}
                >
                  {t("indexPage.button.showAdditionalFilters")}
                </span>
              </>
            )} */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const SearchPanel = (props) => {
  //   const urlParams = queryString.parse(props.props.location.search);
  //   const additionalFiltersDefaultState = (
  //     (urlParams.name) ||
  //     (urlParams.restaurantType) ||
  //     (urlParams.rating) ||
  //     (urlParams.priceLevel)
  //   );
  const additionalFiltersDefaultState = true;
  let [additionalFilters, setAdditionalFilters] = useState(
    additionalFiltersDefaultState
  );

  const hideAdditionalFilters = () => {
    setAdditionalFilters(false);
  };

  const showAdditionalFilters = () => {
    setAdditionalFilters(true);
  };

  const searchFormOnChange = (changes) => {
    if (typeof props.onChange === "function") {
      props.onChange(changes.change, changes.value);
    }
  };

  return (
    <div
      className={classnames({
        backgroundSpace: true,
        "backgroundSpace--additionalFilters": additionalFilters,
      })}
    >
      <div className="backgroundSpace__inside">
        {props.children}
        <SearchForm
          props={props}
          additionalFilters={additionalFilters}
          hideAdditionalFilters={hideAdditionalFilters}
          showAdditionalFilters={showAdditionalFilters}
          onChange={searchFormOnChange}
          configuration={props.configuration}
        />
      </div>
    </div>
  );
};

export default SearchPanel;
